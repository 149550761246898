import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '../components/Button';

function NotFound(){

    useEffect(() => {
        document.title = '404 Олдсонгүй - MyBio.mn';
        document.body.style.backgroundColor = "#ffffff";
    }, []);

    return (
        <div className='notFound'>
            <div className='theNum'>
                404 
            </div>
            <div className='desc'>
                Юу ч олдсонгүй 😢   
            </div>
            <Link to='/'>
                <Button title='Буцах'/>
            </Link>
        </div>
    )
}

export default NotFound;