import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie, deleteCookie } from './utils';

const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME;
const refreshTokenName = process.env.REACT_APP_REFRESH_TOKEN_NAME;

const checkToken = () => {
    if(!getCookie(accessTokenName) || !getCookie(refreshTokenName)) return false;
    return getCookie(accessTokenName);
};

export const isLogged = async () => {
    const res = await sendGet('/api/validateUser');
    if(!res) return false;
    if(res.status == 500) {
        deleteCookie(accessTokenName);
        deleteCookie(refreshTokenName);
    }
    if(res.status == 999) {
        setCookie('noUsername', true);
        return window.location.href = '/selectusername';
    }
    if(res.status != 200) return false;
    return res;
};

export const sendPost = async (link, body) => {
    if(!link) return false;
    const token = checkToken();
    if(!token) return false;
    const authHeader = { headers: {"Authorization" : `Bearer ${token}`} };
    const res = await axios.post(link, body, authHeader);
    if(!res.data) return false;
    if(res.data.status == 403) {
        const tokenRes = await axios.post('/auth/token', { token: getCookie(refreshTokenName) });
        if(!tokenRes.data) return false;
        if(tokenRes.data.status != 200) return false;
        setCookie(accessTokenName, tokenRes.data.accessToken);
        const authHeaderNew = { headers: {"Authorization" : `Bearer ${tokenRes.data.accessToken}`} };
        const repeatRes = await axios.post(link, body, authHeaderNew);
        if(!repeatRes.data) return false;
        if(repeatRes.data.status == 403) return false;
        return repeatRes.data;
    }
    return res.data;
};

export const sendGet = async (link) => {
    if(!link) return false;
    const token = checkToken();
    if(!token) return false;
    const authHeader = { headers: {"Authorization" : `Bearer ${token}`} };
    const res = await axios.get(link, authHeader);
    if(!res.data) return false;
    if(res.data.status == 403) {
        const tokenRes = await axios.post('/auth/token', { token: getCookie(refreshTokenName) });
        if(!tokenRes.data) return false;
        if(tokenRes.data.status != 200) return false;
        console.log(tokenRes.data);
        setCookie(accessTokenName, tokenRes.data.accessToken);
        const authHeaderNew = { headers: {"Authorization" : `Bearer ${tokenRes.data.accessToken}`} };
        const repeatRes = await axios.get(link, authHeaderNew);
        if(!repeatRes.data) return false;
        console.log(repeatRes.data);
        if(repeatRes.data.status == 403) return false;
        return repeatRes.data;
    }
    return res.data;
};


export const sendImage = async (link, data) => {
    if(!link) return false;
    const token = checkToken();
    if(!token) return false;
    const authHeader = { headers: {"Authorization" : `Bearer ${token}`, "Content-Type": "multipart/form-data"} };
    const res = await axios.post(link, data, authHeader);
    if(!res.data) return false;
    if(res.data.status == 403) {
        const tokenRes = await axios.post('/auth/token', { token: getCookie(refreshTokenName) });
        if(!tokenRes.data) return false;
        if(tokenRes.data.status != 200) return false;
        setCookie(accessTokenName, tokenRes.data.accessToken);
        const authHeaderNew = { headers: {"Authorization" : `Bearer ${tokenRes.data.accessToken}`, "Content-Type": "multipart/form-data"} };
        const repeatRes = await axios.post(link, data, authHeaderNew);
        if(!repeatRes.data) return false;
        if(repeatRes.data.status == 403) return false;
        return repeatRes.data;
    }
    return res.data;
};
