import { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { sendGet } from '../../utils/request';
import { getDateName } from '../../utils/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function ViewChart(){
    const [views, setViews] = useState([]);
    const [clicks, setClicks] = useState([]);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        const getStats = async () => {
            const viewsRes = await sendGet('/api/getViewsStatsDays/7');
            if(!viewsRes) return setNoData(true);
            setViews(viewsRes.data.reverse());
            const clicksRes = await sendGet('/api/getClicksStatsDays/7');
            if(!clicksRes) return setNoData(true);
            setClicks(clicksRes.data.reverse());
        };
        getStats();
    }, [])

    const Last7DaysName = () => {
        var days = [];
        for (var i=6; i>=0; i--) {
            var d = new Date();
            d.setDate(d.getDate() - i);
            days.push(getDateName(d.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'Asia/Ulaanbaatar' })));
        }
        return(days);
    };

    const options = {
        responsive: true,
        plugins: { legend: { position: 'bottom' } }
    };

    const labels = Last7DaysName();

    const data = {
        labels,
        datasets: [
            {
                label: 'Харсан',
                data: views,
                borderColor: '#267AE9',
                backgroundColor: '#0a5ac4',
            },
            {
                label: 'Дарсан',
                data: clicks,
                borderColor: '#07AF18',
                backgroundColor: '#058612',
            },
        ],
    };

    return (
        <div className='viewChart block'>
            <div className='top'>
                <div className='title'>
                    Миний үзүүлэлт
                </div>
                {/* <div className='setDate'>
                    <Button title='Сараар харах'/>
                </div> */}
            </div>

            <div className='content'>
                { noData ? (<div style={{textAlign: 'center', fontWeight: 500}}>Хоосон байна</div>)
                : 
                <Line options={options} data={data} /> }
            </div>
        </div>
    )
}


export default ViewChart;