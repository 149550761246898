import React from 'react';

import { Views, Clicks, CTR } from '../../icons/StatsIcons';

function MainStatsSingle({ icon, color, number, desc, tooltip }) {
    const backgroundColor = color + '1A';
    var iconName;
    if(icon === 'view' || icon === 'views'){
        iconName = <Views fill={color}/>;
    }
    else if(icon === 'click' || icon === 'clicks'){
        iconName = <Clicks fill={color}/>;
    }
    else if(icon === 'ctr' || icon === 'CTR'){
        iconName = <CTR fill={color}/>;
    }
    else {
        iconName = <Views fill={color}/>;
    }
    return (
        <div className='statsContentSingle tooltipTrigger'>
            <div className='iconBox' style={{backgroundColor}}>
                {iconName}
            </div>
            <div className='num'>
                <div className='number'>
                    {number}
                </div>
                <div className='desc'>
                    {desc}
                </div>
            </div>
            <div className='tooltip tooltipBottom'>
                {tooltip}
            </div>
        </div>
    )
}

export default MainStatsSingle;
