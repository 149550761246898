import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { containsOneUpper, containsOneLower, containsOneNumber } from '../../utils/utils';
import { sendPost } from '../../utils/request';


import ActionMessage from '../ActionMessage';
import Button from '../Button';


function ChangePassword(){
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [save, setSave] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [oldPassError, setOldPassErorr] = useState('');
    const [repeatPassError, setRepeatPassErorr] = useState('');

    const [enoughLength, setEnoughLength] = useState('/images/icons/password-indicator.svg');
    const [oneUpper, setOneUpper] = useState('/images/icons/password-indicator.svg');
    const [oneLower, setOneLower] = useState('/images/icons/password-indicator.svg');
    const [oneNumber, setOneNumber] = useState('/images/icons/password-indicator.svg');

    useEffect(() => {
        if(password && oldPassword && repeatPassword) {
            if(password != repeatPassword) return;
            if(password.length < 8) return;
            if(!containsOneUpper(password)) return;
            if(!containsOneLower(password)) return;
            if(!containsOneNumber(password)) return;
            setSave(false);
        }
    }, [password, oldPassword, repeatPassword]);

    const oldPasswordHandleChange = (e) => {
        const currentOldPassword = e.target.value;
        setOldPassword(currentOldPassword);
        if(!currentOldPassword || currentOldPassword == ''){
            e.target.style.outline = '2px solid #B00020';
            return setOldPassErorr('Хоосон байж болохгүй!');
        }
        e.target.style.outline = 'none';
        setOldPassErorr('');
    };

    const passwordHandleChange = (e) => {
        const currentPassword = e.target.value;
        const empty = '/images/icons/password-indicator.svg';
        const done = '/images/icons/password-indicator-done.svg';
        setPassword(currentPassword);
        if(currentPassword.length >= 8) setEnoughLength(done);
        else setEnoughLength(empty);
        if(containsOneUpper(currentPassword)) setOneUpper(done);
        else setOneUpper(empty);
        if(containsOneLower(currentPassword)) setOneLower(done);
        else setOneLower(empty);
        if(containsOneNumber(currentPassword)) setOneNumber(done);
        else setOneNumber(empty);
    };

    const passwordRepeatHandleChange = (e) => {
        const currentRepeatPass = e.target.value;
        setRepeatPassword(currentRepeatPass);
        if (e.target.value === '') {
            e.target.style.outline = 'none';
            return setRepeatPassErorr(null);
        }
        if (password !== currentRepeatPass) {
            e.target.style.outline = '2px solid #B00020';
            return setRepeatPassErorr('Адилхан байх ёстой!');
        }
        e.target.style.outline = 'none';
        setRepeatPassErorr(null);
    };

    const savePassword = async () => {
        if(!password) return showError('Шинэ нууц үг хоосон байна');
        if(!repeatPassword) return showError('Шинэ нууц үгээ давтана уу');
        if(!oldPassword) return showError('Хуучин нууц үг хоосон байна');
        if(password.length < 8) return showError('Нууц үг 8-аас бага урттай байна');
        if(!containsOneUpper(password)) return showError('Нууц үг дор хаяж 1 том үсэг ороогүй байна');
        if(!containsOneLower(password)) return showError('Нууц үг дор хаяж 1 жижиг үсэг ороогүй байна');
        if(!containsOneNumber(password)) return showError('Нууц үг дор хаяж 1 тоо ороогүй байна');
        if(password !== repeatPassword) return showError('Давтах нууц үг адилхан байх ёстой');
        const res = await sendPost('/api/updatePassword', { oldPassword, newPassword: password })
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status != 200) return showError(res.error);
        setSave(true);
        showSuccess('Амжилттай солигдлоо 😊');
        setPassword('');
        setOldPassword('');
        setRepeatPassword('');
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 2*1000);
    };

    const showSuccess = (message) => {
        setSuccess(message);
        setTimeout(() => {
            setSuccess('');
        }, 2*1000);
    };


    return (
        <div className='changePassword block'>
            <AnimatePresence>
                {error && <ActionMessage message={error} type='fail' style={{top: '20px'}} />}
                {success && <ActionMessage message={success} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Нууц үг солих
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={savePassword}/>) 
                    }
                </div>
            </div>
            <div className='content'>
                <div className='formSingle'>
                    <div className='formTitle'>
                        Хуучин нууц үг
                    </div>
                    <input type='password' value={oldPassword} onChange={oldPasswordHandleChange} placeholder='Хуучин нууц үгээ оруулна уу' style={{width: '100%'}}/>
                    { oldPassError && <div className='errorMessage'>{oldPassError}</div> }
                </div>

                <div className='formSingle'>
                    <div className='formTitle'>
                        Шинэ нууц үг
                    </div>
                    <input type='password' value={password} onChange={passwordHandleChange} placeholder='Шинэ нууц үгээ оруулна уу' style={{width: '100%'}}/>
                    <div className='passwordIndicator'>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneLower} alt='pass'/>
                            <span>Дор хаяж 1 жижиг үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneUpper} alt='pass'/>
                            <span>Дор хаяж 1 том үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneNumber} alt='pass'/>
                            <span>Дор хаяж 1 тоо орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={enoughLength} alt='pass'/>
                            <span>8 дээш урттай байх</span>
                        </div>
                    </div>
                </div>

                <div className='formSingle'>
                    <div className='formTitle'>
                        Нууц үг давтах
                    </div>
                    <input type='password' value={repeatPassword} onChange={passwordRepeatHandleChange} placeholder='Нууц үгээ давтан оруулна уу' style={{width: '100%'}}/>
                    { repeatPassError && <div className='errorMessage'>{repeatPassError}</div> }
                </div>
                
                
                
                <div className='mobileButton'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={savePassword}/>) 
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;