import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



import Header from '../components/Header';
import LeftMenu from '../components/LeftMenu';
import DashboardStats from '../components/DashboardStats';
import ProfileLink from '../components/ProfileLink';
import Preview from '../components/Preview';
import LinkManager from '../components/dashboard/LinkManager';
import ProfileSelect from '../components/dashboard/ProfileSelect';
import SocialMedia from '../components/dashboard/SocialMedia';
import BackgroundImage from '../components/dashboard/BackgroundImage';
import WriteBio from '../components/dashboard/WriteBio';
import ColorPicker from '../components/dashboard/ColorPicker';
import ChangeName from '../components/dashboard/ChangeName';

import VerifyEmailMessage from '../components/VerifyEmailMessage';

import LoadingScreen from '../components/LoadingScreen';
import { getDashboardData } from '../utils/utils';


function Dashboard() {
    const [links, setLinks] = useState([]);
    const [linksFromDB, setLinkFromDB] = useState([]);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [profile, setProfile] = useState('/uploads/avatar1.png');
    const [bgColor, setBgColor] = useState('#4945FF');
    const [buttonColor, setButtonColor] = useState('#FFFFFF');
    const [buttonTextColor, setButtonTextColor] = useState('#000000');
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});

    const [views, setViews] = useState(null);
    const [clicks, setClicks] = useState(null);
    const [ctr, setCTR] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Удирдах хэсэг | MyBio";
        document.body.style.backgroundColor = "#EAEDF2";
        
        getDashboardData(navigate, setLinks, setEmail, setName, setUsername, setBio, setProfile, setBgColor, setButtonColor, setButtonTextColor, setLoading, setViews, setClicks, setCTR, setUserData, setLinkFromDB);
    }, []);

    const render = (
        <div className="dashboard">
            <Header username={username} home={true}/>
            <LeftMenu home={true}/>
            <div className='dashboardContainer'>
                <div className='left'>
                    <div className='flex'>
                        <DashboardStats views={views} clicks={clicks} ctr={ctr} hideMore={'false'}/>
                        { userData.isEmailVerified === false && (<VerifyEmailMessage/>) }
                        <div className='mobilePreview'>
                            <ProfileLink username={username} />
                        </div>
                        <LinkManager links={links} setLinks={setLinks} linksFromDB={linksFromDB}/>
                        <ChangeName name={name} setName={setName}/>
                        <WriteBio bio={bio} setBio={setBio} />
                        <ProfileSelect profile={profile} setProfile={setProfile} />
                        <ColorPicker bgColor={bgColor} setBgColor={setBgColor} buttonColor={buttonColor} setButtonColor={setButtonColor} buttonTextColor={buttonTextColor} setButtonTextColor={setButtonTextColor}/>
                        <div className='mobilePreview'>
                            <Preview email={email} fullname={name} links={links} bio={bio} profile={profile} bgColor={bgColor} buttonColor={buttonColor} buttonTextColor={buttonTextColor}/>
                        </div>
                        {/* <SocialMedia /> */}
                        <BackgroundImage />
                    </div>
                </div>
                <div className='right'>
                    <div className='fixed'>
                        <ProfileLink username={username} />
                        <Preview email={email} fullname={name} links={links} bio={bio} profile={profile} bgColor={bgColor} buttonColor={buttonColor} buttonTextColor={buttonTextColor} userData={userData}/>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>  
            { loading ? (<LoadingScreen/>) : render }
        </>
    );
}

export default Dashboard;
