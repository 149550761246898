import React from 'react';
import { motion } from 'framer-motion';

function SaveReminder(){
    const spring = {
        type: "spring",
        damping: 10,
        stiffness: 100,
        duration: 6
    }

    return (
        <motion.div className='saveReminder'
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={spring}
        >
            Хадгалах товч дарахаа мартваа ✌️
        </motion.div>
    )
}

export default SaveReminder;