import React from 'react';

// eslint-disable-next-line
import { Facebook, Twitter, Instagram, Youtube, Messenger, Telegram, Snapchat, Tiktok, Whatsapp, Dribbble, Skype, Vimeo, Linkedin, Blogger, Spotify, Tumblers, Pinterest, Twitch, Figma, Vk, Medium, Behance, Deviantart, Soundcloud, Github, Xbox, Wechat, Reddit, Discord } from '../icons/SocialIcons';

function Preview({ email, fullname, links, bio, profile, bgColor, buttonColor, buttonTextColor, userData }){

    return (
        <div className='preview' style={{backgroundColor: bgColor}}>
            <div className='profilePicture' style={{backgroundImage: "url("+profile+")"}}></div>
            <div className='name' style={{color: getTextColor(bgColor)}}>
                {fullname}

                { userData && userData.isVerified === true && (
                    <div className='profileBadge tooltipTrigger'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={getTextColor(bgColor)} xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.1219 9.88002L19.681 9.43901C19.4 9.15801 19.2419 8.77601 19.2419 8.37801V7.757C19.2419 6.101 17.9 4.75902 16.244 4.75902H15.623C15.225 4.75902 14.844 4.60102 14.562 4.32002L14.1209 3.87901C12.9499 2.70801 11.0519 2.70801 9.88094 3.87901L9.4399 4.32002C9.1589 4.60102 8.77698 4.75902 8.37898 4.75902H7.75801C6.10201 4.75902 4.75996 6.101 4.75996 7.757V8.37801C4.75996 8.77601 4.602 9.15701 4.321 9.43901L3.87996 9.88002C2.70896 11.051 2.70896 12.949 3.87996 14.12L4.321 14.561C4.602 14.842 4.75996 15.224 4.75996 15.622V16.243C4.75996 17.899 6.10201 19.241 7.75801 19.241H8.37898C8.77698 19.241 9.1579 19.399 9.4399 19.68L9.88094 20.121C11.0519 21.292 12.9499 21.292 14.1209 20.121L14.562 19.68C14.843 19.399 15.225 19.241 15.623 19.241H16.244C17.9 19.241 19.2419 17.899 19.2419 16.243V15.622C19.2419 15.224 19.4 14.843 19.681 14.561L20.1219 14.12C21.2929 12.949 21.2929 11.051 20.1219 9.88002ZM15.53 10.53L11.53 14.53C11.384 14.676 11.192 14.75 11 14.75C10.808 14.75 10.6159 14.677 10.4699 14.53L8.46992 12.53C8.17692 12.237 8.17692 11.762 8.46992 11.469C8.76292 11.176 9.23796 11.176 9.53096 11.469L11.0009 12.939L14.4709 9.46901C14.7639 9.17601 15.2389 9.17601 15.5319 9.46901C15.8229 9.76201 15.823 10.238 15.53 10.53Z"/>
                        </svg>
                        <div className='tooltip tooltipBottom'>
                            Баталгаажсан
                        </div>
                    </div>
                ) }

                { userData && userData.isPremium === true && (
                    <div className='profileBadge tooltipTrigger'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill={getTextColor(bgColor)} xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 3H7C5 3 4 4 4 6V18.621C4 19.445 4.94098 19.915 5.59998 19.421L6.95898 18.402C7.55598 17.954 8.39204 18.014 8.92004 18.541L10.939 20.56C11.525 21.146 12.4751 21.146 13.0601 20.56L15.079 18.541C15.607 18.013 16.443 17.954 17.04 18.402L18.399 19.421C19.058 19.915 19.999 19.445 19.999 18.621V6C20 4 19 3 17 3ZM16.106 10.488L14.576 11.968C14.464 12.076 14.4129 12.232 14.4399 12.385L14.79 14.41C14.861 14.821 14.428 15.134 14.056 14.941L12.22 13.983C12.082 13.911 11.918 13.911 11.78 13.983L9.94495 14.94C9.57295 15.134 9.13896 14.82 9.20996 14.409L9.56006 12.385C9.58606 12.232 9.53495 12.076 9.42395 11.968L7.89404 10.488C7.61304 10.216 7.76801 9.741 8.15601 9.686L10.2729 9.38101C10.4269 9.35901 10.56 9.26299 10.629 9.12399L11.546 7.28101C11.732 6.90701 12.269 6.90701 12.455 7.28101L13.3719 9.12399C13.4409 9.26299 13.574 9.35901 13.728 9.38101L15.845 9.686C16.233 9.742 16.387 10.216 16.106 10.488Z"/>
                        </svg>

                        <div className='tooltip tooltipBottom'>
                            Premium
                        </div>
                    </div>
                ) }

                { userData && userData.isAdmin === true && (
                    <div className='profileBadge tooltipTrigger' style={{marginLeft: '2px'}}>
                        <svg class="adminBadgeIcon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.9153 0H5.08475C2.27652 0 0 2.27652 0 5.08475V24.9153C0 27.7235 2.27652 30 5.08475 30H24.9153C27.7235 30 30 27.7235 30 24.9153V5.08475C30 2.27652 27.7235 0 24.9153 0Z" fill="#4945FF"/>
                            <path opacity="0.94" d="M21.8861 8.91129C21.9605 9.09165 22 9.28308 22 9.4761V17.3518C22 18.1666 21.3372 18.8279 20.5209 18.8279C19.7047 18.8279 19.0418 18.1666 19.0418 17.3518V13.0406L16.6579 15.42C16.3684 15.708 15.9919 15.852 15.6122 15.852C15.2326 15.852 14.8545 15.708 14.5666 15.42C13.9892 14.8426 13.9892 13.9091 14.5666 13.3317L16.9505 10.9522H12.6319C11.8156 10.9522 11.1528 10.2909 11.1528 9.4761C11.1528 8.66132 11.8156 8 12.6319 8H20.5225C20.7155 8 20.9069 8.03955 21.0873 8.11391C21.4495 8.26421 21.7374 8.55057 21.8877 8.91287L21.8861 8.91129Z" fill="white"/>
                            <path d="M11.1338 18.7868C10.5579 18.2109 10.5579 17.2774 11.1338 16.7031L11.7729 16.0655C12.3487 15.4897 13.2836 15.4897 13.861 16.0655C14.4368 16.6414 14.4368 17.5749 13.861 18.1492L13.2219 18.7868C12.6461 19.3626 11.7112 19.3626 11.1338 18.7868Z" fill="white"/>
                            <path d="M8.43186 21.5681C7.85605 20.9922 7.85605 20.0588 8.43186 19.4844C9.00768 18.9086 9.9426 18.9086 10.52 19.4844C11.0958 20.0603 11.0958 20.9938 10.52 21.5681C9.94418 22.144 9.00927 22.144 8.43186 21.5681Z" fill="white"/>
                        </svg>

                        <div className='tooltip tooltipBottom'>
                            Админ
                        </div>
                    </div>
                ) }
            </div>
            <div className='bio' style={{color: getTextColor(bgColor)}}>
                {bio}
            </div>
            {/* <div className='socialIcon'>
                <Facebook fill='white' />
                <Twitter fill='white' />
                <Instagram fill='white' />
                <Youtube fill='white' />
                <Messenger fill='white' />
                <Telegram fill='white' />
                <Snapchat fill='white' />
                <Tiktok fill='white' />
                <Whatsapp fill='white' />
                <Dribbble fill='white' />
                <Skype fill='white' />
                <Vimeo fill='white' />
                <Linkedin fill='white' />
                <Blogger fill='white' />
                <Spotify fill='white' />
                <Tumblers fill='white' />
                <Pinterest fill='white' />
                <Twitch fill='white' />
                <Figma fill='white' />
                <Vk fill='white' />
                <Medium fill='white' />
                <Behance fill='white' />
                <Deviantart fill='white' />
                <Soundcloud fill='white' />
                <Github fill='white' />
                <Xbox fill='white' />
                <Wechat fill='white' />
                <Reddit fill='white' />
                <Discord fill='white' />
            </div> */}
            <div className='links'>
                {links.length > 0 && links.map(link => (
                    <div key={link.id} className='singleLink' style={{backgroundColor: buttonColor, color: buttonTextColor}}>
                        {link.title}
                    </div>
                ))}
            </div>
        </div>
    );
}


function getRGB(c) {
    return parseInt(c, 16) || c;
}

function getsRGB(c) {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    );
}

function getContrast(f, b) {
    const L1 = getLuminance(f);
    const L2 = getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

function getTextColor(bgColor) {
    const whiteContrast = getContrast(bgColor, "#ffffff");
    const blackContrast = getContrast(bgColor, "#000000");

    return whiteContrast > blackContrast ? "#ffffff" : "#000000";
}


export default Preview;