import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { isUsernameValid, isUsernameExist } from '../../utils/utils';
import { sendPost } from '../../utils/request';


import ActionMessage from '../ActionMessage';
import Button from '../Button';


function ChangeUsername({ username, setUsername }){
    const [save, setSave] = useState(true);
    const [firstUsername, setFirstUsername] = useState(username);
    const [usernameError, setUsernameError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const usernameHandleChange = async (e) => {
        const currentUsername = e.target.value;
        setSave(false);
        setUsername(currentUsername);
        if(currentUsername == '') {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Хоосон байж болохгүй');
        }
        if(currentUsername == firstUsername){
            setSave(true);
            e.target.style.outline = 'none';
            return setUsernameError(null);
        }
        if(currentUsername.length < 3) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('3 ба түүнээс дээш урттай байх ёстой');
        }
        if (!isUsernameValid(currentUsername)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Зөвхөн үсэг, тоо, _ болон цэг ашиглана уу');
        }
        if (await isUsernameExist(currentUsername)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Аль хэдийн бүртгэлтэй байна!');
        }
        e.target.style.outline = 'none';
        setUsernameError(null);
    };

    const saveUsername = async () => {
        if(!username) return showError('Хэрэглэгчийн нэр хоосон байна');
        if(!isUsernameValid(username)) return showError('Хэрэглэгчийн нэрээ зөв оруулна уу');
        if(await isUsernameExist(username)) return showError('Бүртгэлтэй хэрэглэгчийн нэр байна');
        const res = await sendPost('/api/updateUsername', { username });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status != 200) return showError(res.error);
        setFirstUsername(username);
        setSave(true);
        showSuccess('Амжилттай солигдлоо 😊');
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 2*1000);
    };

    const showSuccess = (message) => {
        setSuccess(message);
        setTimeout(() => {
            setSuccess('');
        }, 2*1000);
    };


    return (
        <div className='changeUsername block'>
            <AnimatePresence>
                {error && <ActionMessage message={error} type='fail' style={{top: '20px'}} />}
                {success && <ActionMessage message={success} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Хэрэглэгчийн нэр солих
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveUsername}/>) 
                    }
                </div>
            </div>
            <div className='content'>
                <input type='text' value={username} onChange={usernameHandleChange} style={{width: '100%'}}/>
                { usernameError && <div className='errorMessage'>{usernameError}</div> }
                <div className='mobileButton'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveUsername}/>) 
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangeUsername;