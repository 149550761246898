import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { sendPost } from '../../utils/request';
import { isNameValid } from '../../utils/utils';

import Button from '../Button';
import SaveReminder from '../SaveReminder';
import ActionMessage from '../ActionMessage';

function ChangeName({ name, setName }){
    const [save, setSave] = useState(true);
    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');

    const nameHandleChange = (e) => {
        const currentName = e.target.value;
        setSave(false);
        setName(currentName);
        if (!currentName || currentName == '') {
            e.target.style.outline = '2px solid #B00020';
            return setNameError('Нэр хоосон байна!');
        }
        if (!isNameValid(currentName)) {
            e.target.style.outline = '2px solid #B00020';
            return setNameError('Нэр буруу байна!');
        }
        e.target.style.outline = 'none';
        setNameError(null);
    };

    const saveName = async () => {
        if(!name || name == '') return showError('Нэр хоосон байх боломжгүй');
        if(!isNameValid(name)) return showError('Нэр буруу байна');
        const res = await sendPost('/api/updateName', { name });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status !== 200) return showError(res.error);
        setSave(true);
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 6*1000);
    };

    return (
        <div className='changeName block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Миний нэр
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveName}/>) 
                    }
                </div>
            </div>

            <div className='content'>
                <div className='nameForm'>
                    <input type='text' value={name} onChange={nameHandleChange} placeholder='Өөрийн нэрээ оруулна уу' style={{width: '100%'}}/>
                    { nameError && <div className='errorMessage'>{nameError}</div> }
                </div>
            </div>
        </div>
    )
}


export default ChangeName;