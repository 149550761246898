import { useRef, useEffect } from 'react';

import { DarkMode, LightMode } from '../icons/Icons';

function Switch ({ isOn, handleToggle, colorOne, colorTwo }) {
    const lightRef = useRef();
    const darkRef = useRef();

    useEffect(() => {
        if(localStorage.getItem("MBDark")){
            darkRef.current.classList.add('activeBox');
        }
        else {
            lightRef.current.classList.add('activeBox');
        }
    }, [])

    const darkMode = () => {
        document.body.classList.add('dark');
        lightRef.current.classList.remove('activeBox');
        darkRef.current.classList.add('activeBox');
        localStorage.setItem("MBDark", true);
    };

    const lightMode = () => {
        document.body.classList.remove('dark');
        darkRef.current.classList.remove('activeBox');
        lightRef.current.classList.add('activeBox');
        localStorage.removeItem("MBDark");
    };

    return (
        <div className='darkModeSwitch'>
            <div className='darkModeSwitchBox' ref={lightRef} onClick={lightMode}>
                <LightMode />
            </div>
            <div className='darkModeSwitchBox' ref={darkRef} onClick={darkMode}>
                <DarkMode />
            </div>
        </div>
    );
};


export default Switch;