import React from 'react';


function ButtonIcons({ icon, fill }) {
    if(!fill) fill = 'none';
    if(icon == 'saved'){
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M16 19.75H9.5C5.502 19.75 2.25 16.498 2.25 12.5C2.25 8.502 5.502 5.25 9.5 5.25C11.264 5.25 12.9641 5.89198 14.2881 7.05798C14.7021 7.42298 15.0711 7.83303 15.3921 8.28003C15.5931 8.25903 15.794 8.24902 16 8.24902C19.171 8.24902 21.75 10.828 21.75 13.999C21.75 17.17 19.171 19.75 16 19.75ZM9.5 6.75C6.329 6.75 3.75 9.329 3.75 12.5C3.75 15.671 6.329 18.25 9.5 18.25H16C18.344 18.25 20.25 16.344 20.25 14C20.25 11.656 18.344 9.75 16 9.75C15.717 9.75 15.4449 9.77503 15.1689 9.82703C14.8669 9.88503 14.5551 9.74801 14.3931 9.48401C14.0931 8.99901 13.7239 8.56102 13.2959 8.18402C12.2469 7.25902 10.898 6.75 9.5 6.75ZM11.863 15.364L14.53 12.697C14.823 12.404 14.823 11.929 14.53 11.636C14.237 11.343 13.762 11.343 13.469 11.636L11.332 13.772L10.5291 12.969C10.2361 12.676 9.76102 12.676 9.46802 12.969C9.17502 13.262 9.17502 13.737 9.46802 14.03L10.801 15.363C10.942 15.504 11.1321 15.583 11.3311 15.583C11.5321 15.583 11.723 15.504 11.863 15.364Z"/>
            </svg>
        )
    }
    if(icon == 'save'){
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M21.75 11C21.75 13.727 19.8139 16.097 17.1479 16.635C17.0979 16.645 17.048 16.65 16.999 16.65C16.649 16.65 16.3369 16.404 16.2649 16.048C16.1829 15.642 16.4461 15.246 16.8521 15.165C18.8221 14.768 20.25 13.016 20.25 11C20.25 8.656 18.344 6.75 16 6.75C15.717 6.75 15.4449 6.77503 15.1689 6.82703C14.8639 6.88503 14.5551 6.74801 14.3931 6.48401C14.1011 6.01301 13.7301 5.57298 13.2891 5.17798C12.2511 4.25798 10.904 3.75 9.5 3.75C6.329 3.75 3.75 6.329 3.75 9.5C3.75 11.828 5.13906 13.911 7.28906 14.808C7.67106 14.967 7.85089 15.407 7.69189 15.789C7.53189 16.172 7.09094 16.351 6.71094 16.192C4.00094 15.062 2.25 12.435 2.25 9.5C2.25 5.502 5.502 2.25 9.5 2.25C11.271 2.25 12.9721 2.89202 14.2871 4.05902C14.7071 4.43502 15.0761 4.84401 15.3911 5.28101C15.5921 5.26001 15.794 5.25 16 5.25C19.171 5.25 21.75 7.829 21.75 11ZM12.531 10.47C12.462 10.401 12.3791 10.346 12.2871 10.308C12.1041 10.232 11.8971 10.232 11.7141 10.308C11.6221 10.346 11.539 10.401 11.47 10.47L9.46997 12.47C9.17697 12.763 9.17697 13.238 9.46997 13.531C9.76297 13.824 10.238 13.824 10.531 13.531L11.251 12.811V21C11.251 21.414 11.587 21.75 12.001 21.75C12.415 21.75 12.751 21.414 12.751 21V12.811L13.4709 13.531C13.6169 13.677 13.809 13.751 14.001 13.751C14.193 13.751 14.385 13.678 14.531 13.531C14.824 13.238 14.824 12.763 14.531 12.47L12.531 10.47Z"/>
            </svg>
        )
    }
    if(icon == 'check-double'){
        return (
            <svg width="20" height="12" viewBox="0 0 20 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M19.53 1.52999L9.52999 11.53C9.38899 11.671 9.19799 11.75 8.99999 11.75C8.99899 11.75 8.99799 11.75 8.99699 11.75C8.79699 11.749 8.60599 11.669 8.46499 11.526L4.46499 7.46398C4.17399 7.16898 4.178 6.69401 4.473 6.40301C4.768 6.11301 5.24399 6.116 5.53399 6.411L9.003 9.93499L18.469 0.468987C18.762 0.175987 19.237 0.175987 19.53 0.468987C19.823 0.761987 19.823 1.23799 19.53 1.52999ZM8.99999 7.74999C9.19199 7.74999 9.38399 7.67699 9.52999 7.52999L15.53 1.52999C15.823 1.23699 15.823 0.761987 15.53 0.468987C15.237 0.175987 14.762 0.175987 14.469 0.468987L8.46899 6.46899C8.17599 6.76199 8.17599 7.23699 8.46899 7.52999C8.61599 7.67699 8.80799 7.74999 8.99999 7.74999ZM1.53399 6.41399C1.24399 6.11899 0.769 6.115 0.473 6.406C0.178 6.697 0.173989 7.171 0.464989 7.467L4.46499 11.527C4.61099 11.676 4.80499 11.751 4.99899 11.751C5.18899 11.751 5.37899 11.679 5.52499 11.535C5.81999 11.244 5.824 10.77 5.533 10.474L1.53399 6.41399Z"/>
            </svg>
        )
    }
    if(icon == 'eye'){
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M17.696 8.44836C16.6018 6.61586 14.1459 3.54175 10.0001 3.54175C5.85422 3.54175 3.3983 6.61586 2.30413 8.44836C1.73163 9.40503 1.73163 10.5943 2.30413 11.5518C3.3983 13.3843 5.85422 16.4584 10.0001 16.4584C14.1459 16.4584 16.6018 13.3843 17.696 11.5518C18.2685 10.5943 18.2685 9.40586 17.696 8.44836ZM16.6234 10.9101C15.6651 12.5151 13.5292 15.2084 10.0001 15.2084C6.47089 15.2084 4.33506 12.5159 3.37672 10.9101C3.04172 10.3484 3.04172 9.6509 3.37672 9.08923C4.33506 7.48423 6.47089 4.79093 10.0001 4.79093C13.5292 4.79093 15.6651 7.4834 16.6234 9.08923C16.9592 9.65173 16.9592 10.3484 16.6234 10.9101ZM10.0001 6.45841C8.04672 6.45841 6.45839 8.04758 6.45839 10.0001C6.45839 11.9526 8.04672 13.5417 10.0001 13.5417C11.9534 13.5417 13.5417 11.9526 13.5417 10.0001C13.5417 8.04758 11.9534 6.45841 10.0001 6.45841ZM10.0001 12.2917C8.73589 12.2917 7.70839 11.2642 7.70839 10.0001C7.70839 8.73591 8.73589 7.70841 10.0001 7.70841C11.2642 7.70841 12.2917 8.73591 12.2917 10.0001C12.2917 11.2642 11.2642 12.2917 10.0001 12.2917Z"/>
            </svg>
        )
    }
    if(icon == 'user'){
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0075 8.95833C8.0542 8.95833 6.46586 7.36917 6.46586 5.41667C6.46586 3.46417 8.0542 1.875 10.0075 1.875C11.9609 1.875 13.5492 3.46417 13.5492 5.41667C13.5492 7.36917 11.9609 8.95833 10.0075 8.95833ZM10.0075 3.125C8.74336 3.125 7.71586 4.1525 7.71586 5.41667C7.71586 6.68083 8.74336 7.70833 10.0075 7.70833C11.2717 7.70833 12.2992 6.68083 12.2992 5.41667C12.2992 4.1525 11.2709 3.125 10.0075 3.125ZM13.3308 18.125H6.66921C4.65254 18.125 3.54167 17.0209 3.54167 15.0159C3.54167 12.7984 4.79667 10.2083 8.33333 10.2083H11.6667C15.2033 10.2083 16.4583 12.7975 16.4583 15.0159C16.4583 17.0209 15.3475 18.125 13.3308 18.125ZM8.33333 11.4583C5.0475 11.4583 4.79167 14.1809 4.79167 15.0159C4.79167 16.3192 5.35338 16.875 6.66921 16.875H13.3308C14.6466 16.875 15.2083 16.3192 15.2083 15.0159C15.2083 14.1817 14.9525 11.4583 11.6667 11.4583H8.33333Z"/>
            </svg>
        )
    }
    if(icon == 'key'){
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M15 2.25C11.278 2.25 8.25 5.278 8.25 9C8.25 9.515 8.3081 10.0231 8.4231 10.5171L2.46997 16.47C2.32897 16.611 2.25 16.802 2.25 17V21C2.25 21.414 2.586 21.75 3 21.75H7C7.414 21.75 7.75 21.414 7.75 21V18.75H10C10.199 18.75 10.39 18.671 10.53 18.53L13.4829 15.5769C13.9759 15.6919 14.484 15.75 15 15.75C18.722 15.75 21.75 12.722 21.75 9C21.75 5.278 18.722 2.25 15 2.25ZM15 14.25C14.479 14.25 13.967 14.1739 13.481 14.0229C13.214 13.9399 12.925 14.012 12.729 14.21L9.68896 17.25H7C6.586 17.25 6.25 17.586 6.25 18V20.25H3.75V17.311L9.79004 11.271C9.98604 11.074 10.0591 10.785 9.97705 10.52C9.82705 10.034 9.75 9.52198 9.75 9.00098C9.75 6.10598 12.105 3.75098 15 3.75098C17.895 3.75098 20.25 6.10598 20.25 9.00098C20.25 11.896 17.895 14.25 15 14.25ZM17.27 8C17.27 8.69 16.71 9.25 16.02 9.25C15.331 9.25 14.7649 8.69 14.7649 8C14.7649 7.31 15.32 6.75 16.01 6.75H16.02C16.71 6.75 17.27 7.31 17.27 8Z"/>
            </svg>
        )
    }
    if(icon == 'next'){
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M17.255 10.59L13.9225 13.9226C13.76 14.0851 13.5467 14.1667 13.3333 14.1667C13.12 14.1667 12.9066 14.0851 12.7441 13.9226C12.4183 13.5967 12.4183 13.07 12.7441 12.7442L14.6549 10.8334H3.33333C2.8725 10.8334 2.5 10.46 2.5 10C2.5 9.54003 2.8725 9.1667 3.33333 9.1667H14.6549L12.7441 7.25589C12.4183 6.93006 12.4183 6.40334 12.7441 6.0775C13.07 5.75167 13.5967 5.75167 13.9225 6.0775L17.255 9.41002C17.3325 9.48752 17.3934 9.57913 17.4359 9.68163C17.5201 9.88496 17.5201 10.1151 17.4359 10.3184C17.3934 10.4209 17.3325 10.5125 17.255 10.59Z"/>
            </svg>
        )
    }
};

export default ButtonIcons;