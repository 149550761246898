import { useState, useEffect } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setCookie, getCookie, isEmailValid } from '../utils/utils';
import { AnimatePresence } from 'framer-motion';

import Button from '../components/Button';
import ActionMessage from '../components/ActionMessage';
import LoadingScreen from '../components/LoadingScreen';

function Login(){
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [loginError, setLoginError] = useState('');
    const [loginSuccess, setLoginSuccess] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const url = query.get('url')

    useEffect(() => {
        document.title = 'Нэвтрэх';
        document.body.style.backgroundColor = "#ffffff";
        if(getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME)) return navigate("/");
        setLoading(false);
        // eslint-disable-next-line
    }, []);
    
    var landingURL = '/';
    if(process.env.REACT_APP_LANDING_PAGE_URL) landingURL = process.env.REACT_APP_LANDING_PAGE_URL;

    const login = async () => {
        setLoadingButton(true);
        if(!email) return showError('Имэйл хаяг хоосон байна');
        if(!isEmailValid(email)) return showError('Имэйл хаяг буруу байна');
        if(!password) return showError('Нууц үг хоосон байна');
        const loginData = { email, password };
        const res = await axios.post('/auth/login', loginData);
        if(res.data.status !== 200) {
            if(res.data.status === 404) return showError('Имэйл эсвэл нууц үг буруу байна');
            return showError(res.data.error);
        }
        setCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME, res.data.accessToken);
        setCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME, res.data.refreshToken);
        setLoadingButton(false);
        setLoginSuccess('Тавтай морил 😊');
        setTimeout(() => {
            if(url) navigate(url);
            else navigate("/");
            setLoginSuccess('');
        }, 1000);
    };

    const emailHandleChange = async (e) => {
        setEmail(e.target.value);
        if (e.target.value === '') {
            e.target.style.outline = 'none';
            return setEmailError(null);
        }
        if (!isEmailValid(e.target.value)) {
            e.target.style.outline = '2px solid #B00020';
            return setEmailError('Имэйл хаяг буруу байна!');
        }
        e.target.style.outline = 'none';
        setEmailError(null);
    };

    const passwordHandleChange = (e) => {
        setPassword(e.target.value);
    };

    const enterHandler = (e) => {
        if(e.key === 'Enter' && !loadingButton) login();
    };


    const loginWithGoogle = async (credits) => {
        setLoadingButton(true);
        const ipData = await axios.get('https://geolocation-db.com/json/');
        if(ipData) credits.country = ipData.data;
        const res = await axios.post('/auth/google', credits);
        if(res.data.status !== 200) return showError('Алдаа гарлаа. Дахин оролдоно уу.');
        setCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME, res.data.accessToken);
        setCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME, res.data.refreshToken);
        if(res.data.newUser == true) {
            setCookie('noUsername', true);
            return navigate("/selectusername");
        }
        setLoginSuccess('Тавтай морил 😊');
        setTimeout(() => {
            setLoadingButton(false);
            if(url) navigate(url);
            else navigate("/");
            setLoginSuccess('');
        }, 1000);
    };

    const loginWithGoogleFail = () => {
        showError('Алдаа гарлаа. Дахин оролдоно уу.');
    };

    const showError = (message) => {
        setLoadingButton(false);
        setLoginError(message);
        setTimeout(() => {
            setLoginError('');
        }, 8*1000);
    };

    const render = (
        <div className='loginRegister'>
            <AnimatePresence>
                {loginError && <ActionMessage message={loginError} type='fail' style={{top: '20px'}} />}
                {loginSuccess && <ActionMessage message={loginSuccess} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='title'>
                    Эргээд тавтай морил
                </div>
                <div className='desc'>
                    Өөрийн бүртгэлийн мэдээллээ оруулна уу 😊
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Имэйл хаяг</span>
                        </div>
                        <input type='email' onChange={emailHandleChange} onKeyDown={enterHandler} value={email} placeholder='Имэйл хаягаа оруулна уу'/>
                        { emailError && <div className='errorMessage'>{emailError}</div> }
                    </div>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Нууц үг</span>
                            <div className='inputForgotPass'>
                                <Link to='/forgot'>Нууц үгээ мартсан</Link>
                            </div>
                        </div>
                        <input type='password' onChange={passwordHandleChange} onKeyDown={enterHandler} value={password} />
                    </div>
                    <div className='inputButton'>
                        {loadingButton ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Нэвтрэх' size='100%' onClick={login}/>
                        )}
                    </div>
                </div>
                <div className='noAccount'>
                    Танд бүртгэл байхгүй байна уу? <Link to='/register'><span>Бүртгүүлэх</span></Link>
                </div>

                <div className='loginWithSocial'>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                            onSuccess={credentialResponse => loginWithGoogle(credentialResponse)}
                            onError={() => loginWithGoogleFail()}
                            type='standard'
                            theme='filled_black'
                            size='large'
                            text='signin_with'
                            shape='pill'
                            width='280px'
                            logo_alignment='left'
                            locale='mn'
                        />
                    </GoogleOAuthProvider>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {loading ? (<LoadingScreen/>) : render }
        </>
    )
}

export default Login;