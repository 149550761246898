import { useState } from 'react';
import { Edit, Delete } from '../../icons/Icons';
import { useMotionValue, Reorder, useDragControls, AnimatePresence } from 'framer-motion';
import { useRaisedShadow } from './raisedShadow';
import { validURL } from '../../utils/utils';

import Button from '../Button';
import ActionMessage from '../ActionMessage';

function LinkSingle({ data, list, set, saveEdit, setSaveEdit, saveLink }){
    const [title, setTitle] = useState(data.title);
    const [titleError, setTitleError] = useState('');
    const [url, setURL] = useState(data.url);
    const [urlError, setURLError] = useState('');
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');

    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const dragControls = useDragControls();


    const deleteLink = () => {
        const index = list.indexOf(data);
        if (index > -1) {
            list.splice(index, 1); 
        }
        const newList = [...list];
        set(newList);
        setSaveEdit(false);
    }

    const titleChangeHandle = (e) => {
        const inputTitle = e.target.value;
        setTitle(inputTitle);
        setSaveEdit(false);
        if(!inputTitle || inputTitle === ''){
            e.target.style.outline = '2px solid #B00020';
            return setTitleError('Хоосон байх боломжгүй!');
        }
        setError('');
        e.target.style.outline = 'none';
        setTitleError(null);
    };

    const urlChangeHandle = (e) => {
        const inputURL = e.target.value;
        setURL(inputURL);
        setSaveEdit(false);
        if(!inputURL || inputURL === ''){
            e.target.style.outline = '2px solid #B00020';
            return setURLError('Хоосон байх боломжгүй!');
        }
        if(!validURL(inputURL)){
            e.target.style.outline = '2px solid #B00020';
            return setURLError('URL буруу байна!');
        }
        setError('');
        e.target.style.outline = 'none';
        setURLError(null);
    };

    const editHandle = () => {
        if(edit) setEdit(false);
        else setEdit(true);
    };

    const saveHandle = () => {
        if(!title || title === '') return showError('Гарчиг хоосон байх боломжгүй!');
        if(!url || url === '') return showError('URL хоосон байх боломжгүй!');
        if(!validURL(url)) return showError('URL буруу байна!');
        var currentList = [...list];
        var objIndex = currentList.findIndex((obj => obj.id === data.id));
        currentList[objIndex].title = title;
        currentList[objIndex].url = url;
        set(currentList);
        setSaveEdit(true);
        setEdit(false);
        saveLink();
    };
    

    const showError = (message) => {
        setSaveEdit(false);
        setError(message);
        setTimeout(() => {
            setError('');
        }, 8*1000);
    };


    return (
        <Reorder.Item
            value={data}
            id={data.id}
            style={{ boxShadow, y }}
            // dragListener={false}
            // dragControls={dragControls}
            whileDrag={{zIndex: 99999}}
        >
        <AnimatePresence>
            {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
        </AnimatePresence>
        <div className='linkSingle'>
            <div className='linkLeft'>
                {/* <div className='linkDrag' onPointerDown={(event) => dragControls.start(event)}> */}
                <div className='linkDrag'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="nonee" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 19C10 20.104 9.105 21 8 21C6.895 21 6 20.104 6 19C6 17.896 6.895 17 8 17C9.105 17 10 17.896 10 19ZM8 10C6.895 10 6 10.896 6 12C6 13.104 6.895 14 8 14C9.105 14 10 13.104 10 12C10 10.896 9.105 10 8 10ZM8 3C6.895 3 6 3.896 6 5C6 6.104 6.895 7 8 7C9.105 7 10 6.104 10 5C10 3.896 9.105 3 8 3ZM16 17C14.895 17 14 17.896 14 19C14 20.104 14.895 21 16 21C17.105 21 18 20.104 18 19C18 17.896 17.105 17 16 17ZM16 10C14.895 10 14 10.896 14 12C14 13.104 14.895 14 16 14C17.105 14 18 13.104 18 12C18 10.896 17.105 10 16 10ZM16 3C14.895 3 14 3.896 14 5C14 6.104 14.895 7 16 7C17.105 7 18 6.104 18 5C18 3.896 17.105 3 16 3Z"/>
                    </svg>
                </div>
                <div className='linkContent'>
                    <div className='linkTitle'>
                        {title}
                    </div>
                    <div className='linkURL'>
                        {url}
                    </div>
                </div>
            </div>
            <div className='linkRight' onClick={editHandle}>
                <div className='linkEdit clickable'>
                    <Edit />
                </div>
                <div className='linkDelete clickable' onClick={deleteLink}>
                    <Delete />
                </div>
            </div>
        </div>
        
        {edit && (<div className='linkEditSection'>
            <div className='form'>
                <div className='formSingle'>
                    <div className='formTitle'>
                        Гарчиг
                    </div>
                    <input type='text' onChange={titleChangeHandle} value={title}/>
                    { titleError && <div className='errorMessage'>{titleError}</div> }
                </div>
                <div className='formSingle'>
                    <div className='formTitle'>
                        URL
                    </div>
                    <input type='text' onChange={urlChangeHandle} value={url}/>
                    { urlError && <div className='errorMessage'>{urlError}</div> }
                </div>
            </div>
            <div className='linkEditSaveButton' style={{textAlign: 'right', marginTop: '24px', verticalAlign: 'top'}}>
                <div className='close' onClick={() => {setEdit(false)}}>Хаах</div>
                
                { saveEdit ?
                    (<Button title='Болсон' secondary={true}/>) :
                    (<Button title='Болсон' icon='check-double' onClick={saveHandle}/>) 
                }
            </div>
        </div>)}
        </Reorder.Item>
    )
}

export default LinkSingle;