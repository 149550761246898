import { useEffect, useState } from 'react';
import { sendPost } from '../utils/request';
import { useNavigate } from 'react-router-dom';

function VerifyEmail(){
    const [success, setSuccess] = useState(false);
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const email = query.get('email');
    

    const navigate = useNavigate();

    useEffect(() => {
        const checkCode = async () => {
            const res = await sendPost('/auth/checkVerification', { email, code, type: 'email' });
            if(!res) return navigate('/');
            if(res.status !== 200) return navigate('/');
            setSuccess(true);
            setTimeout(() => {
                navigate('/');
            }, 2000);
        };

        checkCode();
    }, []);

    return (
        <div className='verified'>
            { success && (<>
                <div className='icon'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1.25C6.072 1.25 1.25 6.073 1.25 12C1.25 17.927 6.072 22.75 12 22.75C17.928 22.75 22.75 17.927 22.75 12C22.75 6.073 17.928 1.25 12 1.25ZM12 21.25C6.899 21.25 2.75 17.101 2.75 12C2.75 6.899 6.899 2.75 12 2.75C17.101 2.75 21.25 6.899 21.25 12C21.25 17.101 17.101 21.25 12 21.25ZM16.03 9.13599C16.323 9.42899 16.323 9.90402 16.03 10.197L11.363 14.864C11.217 15.01 11.025 15.084 10.833 15.084C10.641 15.084 10.449 15.011 10.303 14.864L7.97 12.531C7.677 12.238 7.677 11.763 7.97 11.47C8.263 11.177 8.73801 11.177 9.03101 11.47L10.834 13.273L14.97 9.13702C15.263 8.84402 15.737 8.84399 16.03 9.13599Z"/>
                    </svg>
                </div>

                <div className='title'>
                    Амжилттай баталгаажлаа 😊
                </div>

                <div className='desc'>
                    Таныг нүүр хуудас рүү шилжүүлж байна...
                </div>
            </>) }
        </div>
    );
};

export default VerifyEmail;