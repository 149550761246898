import Cookies from 'js-cookie';
import axios from 'axios';

import { isLogged, sendGet } from './request';

export const setCookie = (name, value, exdays = 7) => {
    Cookies.set(name, value, { expires: exdays, path: '/', sameSite: 'lax', domain: process.env.REACT_APP_DOMAIN })
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const deleteCookie = (name) => {
    return Cookies.remove(name, { path: '/', domain: process.env.REACT_APP_DOMAIN });
};

export const isUsernameValid = (username) => {
    if(!username) return false;
    if(username.length < 3) return false;
    const regex = new RegExp('^[a-zA-Z0-9._]+$');
    return regex.test(username);
};

export const isUserIdValid = (id) => {
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    return regex.test(id);
};

export const isEmailValid = (email) => {
    // eslint-disable-next-line
    const regex = new RegExp(/^[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/, "i");
    return regex.test(email);
};

export const isNameValid = (name) => {
    const regex = new RegExp('^[\u04ae\u04af\u04e8\u04e9\u0401\u0451\u0410-\u044fa-zA-Z\' ]+$');
    return regex.test(name);
};

export const isPhoneNumberValid = (phone) => {
    const regex = new RegExp(/^[+]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$/);
    return regex.test(phone);
};

export const isEmailExist = async (email) => {
    const res = await axios.get('/api/getUserByEmail/' + email);
    if(res.data.status === 200) return true;
    else return false;
};

export const isUsernameExist = async (username) => {
    const res = await axios.get('/api/getUserByUsername/' + username);
    if(res.data.status === 200 || res.data.status === 201) return true;
    else return false;
};

export const containsOneUpper = (text) => {
    const regex = new RegExp('(.*[A-Z].*)');
    return regex.test(text);
};

export const containsOneLower = (text) => {
    const regex = new RegExp('(.*[a-z].*)');
    return regex.test(text);
};

export const containsOneNumber = (text) => {
    const regex = new RegExp('(.*[0-9].*)');
    return regex.test(text);
};

export const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        "(?:" +
            "(?:" +
            "[a-z0-9\\u00a1-\\uffff]" +
            "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
            ")?" +
            "[a-z0-9\\u00a1-\\uffff]\\." +
        ")+" +
        "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
        ")" +
        "(?::\\d{2,5})?" +
        "(?:[/?#]\\S*)?" +
        "$", "i"
    );
    return !!pattern.test(str);
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
};

export const getCTR = (views, clicks) => {
    views = parseInt(views);
    clicks = parseInt(clicks);
    if(views == 0 && clicks == 0) return 0;
    if(views > 0 && clicks == 0) return 0;
    if(views == 0 && clicks > 0) return 0;
    return Math.floor(clicks / views * 100);
};


export const getDateName = (dateName) => {
    dateName = dateName.toLowerCase();
    if(dateName == 'monday') return 'Даваа';
    if(dateName == 'tuesday') return 'Мягмар';
    if(dateName == 'wednesday') return 'Лхагва';
    if(dateName == 'thursday') return 'Пүрэв';
    if(dateName == 'friday') return 'Баасан';
    if(dateName == 'saturday') return 'Бямба';
    if(dateName == 'sunday') return 'Ням';
    return 'Тодорхоогүй'; 
};



export const getDashboardData = async (navigate, setLinks, setEmail, setName, setUsername, setBio, setProfile, setBgColor, setButtonColor, setButtonTextColor, setLoading, setViews, setClicks, setCTR, setUserData, setLinksFromDB = null) => {
    const res = await isLogged();
    if(!res) {
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
        deleteCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME);
        return navigate('/login');
    }
    if(res.status === 404){
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
        deleteCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME);
        return navigate('/login');
    }
    const data = res.data;
    if(!data) {
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
        deleteCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME);
        return navigate('/login');
    }
    if(!data.profileImage) setProfile('/uploads/avatar1.png');
    else { 
        if(data.profileImage.startsWith('avatar')) setProfile('/uploads/' + data.profileImage);
        else {
            if(data.profileImage.startsWith('http')) setProfile(data.profileImage);
            else setProfile('/uploads/' + data._id + '/' + data.profileImage);
        }
    }
    if(data.bio) setBio(data.bio);
    if(data.links) {
        setLinks(data.links);
        if(setLinksFromDB) setLinksFromDB(data.links);
    }
    if(data.colors.bgColor) setBgColor(data.colors.bgColor);
    if(data.colors.buttonColor) setButtonColor(data.colors.buttonColor);
    if(data.colors.buttonTextColor) setButtonTextColor(data.colors.buttonTextColor);
    setUsername(data.username);
    setName(data.name);
    setEmail(data.email);
    const viewRes = await sendGet('/api/getViews');
    if(viewRes){
        if(viewRes.status == 200) setViews(viewRes.data);
    }
    const clickRes = await sendGet('/api/getClicks');
    if(clickRes){
        if(clickRes.status == 200) setClicks(clickRes.data);
    }
    setCTR(getCTR(viewRes.data, clickRes.data));
    setUserData(data);
    setLoading(false);
};