import { useEffect, useState } from 'react';
import { getDashboardData } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import LeftMenu from '../components/LeftMenu';
import DashboardStats from '../components/DashboardStats';
import ProfileLink from '../components/ProfileLink';
import Preview from '../components/Preview';
import ViewChart from '../components/stats/ViewChart';
import LinkStats from '../components/stats/LinkStats';

import VerifyEmailMessage from '../components/VerifyEmailMessage';

import LoadingScreen from '../components/LoadingScreen';

function Stats(){
    const [links, setLinks] = useState([]);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [profile, setProfile] = useState('/uploads/avatar1.png');
    const [bgColor, setBgColor] = useState('#4945FF');
    const [buttonColor, setButtonColor] = useState('#FFFFFF');
    const [buttonTextColor, setButtonTextColor] = useState('#000000');
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});

    const [views, setViews] = useState(null);
    const [clicks, setClicks] = useState(null);
    const [ctr, setCTR] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Статистик";
        document.body.style.backgroundColor = "#EAEDF2";
        
        getDashboardData(navigate, setLinks, setEmail, setName, setUsername, setBio, setProfile, setBgColor, setButtonColor, setButtonTextColor, setLoading, setViews, setClicks, setCTR, setUserData);
    }, []);

    const render = (
        <div className="dashboard">
            <Header username={username} stats={true}/>
            <LeftMenu stats={true}/>
            <div className='dashboardContainer'>
                <div className='left'>
                    <div className='flex'>
                        <DashboardStats views={views} clicks={clicks} ctr={ctr} hideMore={'true'}/>
                        <div className='mobilePreview'>
                            <ProfileLink username={username} />
                        </div>
                        { userData.isEmailVerified === false && (<VerifyEmailMessage/>) }
                        <ViewChart />
                        <LinkStats links={links}/>
                        <div className='linkStats block'>
                            <div className='top'>
                                <div className='title'>
                                    Дэлгэрэнгүй
                                </div>
                                <div className='secondary'>
                                    Тун удахгүй
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='fixed'>
                        <ProfileLink username={username} />
                        <Preview email={email} fullname={name} links={links} bio={bio} profile={profile} bgColor={bgColor} buttonColor={buttonColor} buttonTextColor={buttonTextColor} userData={userData}/>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            { loading ? <LoadingScreen/> : render }
        </>
    )
}


export default Stats;