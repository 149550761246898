import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { sendPost } from '../../utils/request';

import Button from '../Button';
import SaveReminder from '../SaveReminder';
import ActionMessage from '../ActionMessage';

function WriteBio({ bio, setBio }){
    const [save, setSave] = useState(true);
    const [error, setError] = useState('');

    const bioChangeHandle = (e) => {
        const currentBio = e.target.value;
        setBio(currentBio);
        setSave(false);
    };

    const saveBio = async () => {
        const res = await sendPost('/api/updateBio', { bio });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status !== 200) return showError(res.error);
        setSave(true);
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 8*1000);
    };

    return (
        <div className='writeBio block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title' id='bioFormTitle'>
                    Миний тухай
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveBio}/>) 
                    }
                </div>
            </div>

            <div className='content'>
                <div className='bio'>
                    <textarea aria-labelledby="bioFormTitle" style={{width: '100%', height: '150px'}} onChange={bioChangeHandle} value={bio} />
                </div>
            </div>
        </div>
    )
}


export default WriteBio;