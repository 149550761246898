import { useEffect, useState } from 'react';
import { Reorder } from 'framer-motion';
import { Add } from '../../icons/Icons';
import { AnimatePresence } from 'framer-motion';
import { sendPost } from '../../utils/request';

import Button from '../Button';
import LinkSingle from '../linkManage/LinkSingle';
import SaveReminder from '../SaveReminder';
import ActionMessage from '../ActionMessage';

function LinkManager({links, setLinks, linksFromDB}) {
    const [save, setSave] = useState(true);
    const [saveEdit, setSaveEdit] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLinks(links);
    }, [links, setLinks]);

    useEffect(() => {
        if(linksFromDB !== links) setSave(false);
    }, [links]);


    const addLink = () => {
        var currentLinks = [...links];
        if(currentLinks.length >= 50) return showError('50-аас дээш линк оруулах боломжгүй');
        var whileToggle = true, newID;
        while(whileToggle){
            // eslint-disable-next-line
            newID = Math.floor(Math.random() * 999999);
            // eslint-disable-next-line
            var checkIndex = currentLinks.findIndex((obj => obj.id == newID));
            if(checkIndex < 0) whileToggle = false;
        }
        currentLinks.push({ id: newID, title: 'Шинэ холбоос', url: 'https://mybio.mn/' });
        setLinks(currentLinks);
        setSave(false);
    };

    const saveLink = async () => {
        const res = await sendPost('/api/updateLinks', { links });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу')
        if(res.status !== 200) return showError(res.error);
        setSave(true);
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 8*1000);
    };

    return (
        <div className='linkManager block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Холбоос
                </div>
                <div className='secondary'>
                    { save ?
                    (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                    (<Button title='Хадгалах' icon='save' onClick={saveLink}/>) }
                </div>
            </div>
            <div className='links'>
                <Reorder.Group as="ol" axis="y" onReorder={setLinks} values={links}>
                    {links.length > 0 && links.map((link) => (
                        <LinkSingle key={link.id} data={link} list={links} set={setLinks} saveEdit={saveEdit} setSaveEdit={setSaveEdit} saveLink={saveLink}/>
                    ))}
                </Reorder.Group>

                <div className='addLinkButton' onClick={addLink}>
                    <Add />
                    <span>Шинэ холбоос нэмэх</span>
                </div>
            </div>
        </div>
    )
}

export default LinkManager;
