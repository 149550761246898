import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import Button from './Button';
import Switch from './Switch';
import { Menu, MenuClose, Home, Stats, Settings } from '../icons/Icons';

function Header(props){
    const { username } = props;
    const [isOpened, setIsOpened] = useState(false);
    const [checked, setChecked] = useState(true);
    const link = process.env.REACT_APP_LANDING_PAGE_URL + username;
    const mobileNavRef = useRef(null);
    useOutsideAlerter(mobileNavRef, isOpened, setIsOpened);

    return (
        <div className='header'>
            {isOpened && (
                <AnimatePresence>
                    <motion.div className='mobileNav'
                        ref={mobileNavRef}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MobileNav data={props} isOpened={isOpened} setIsOpened={setIsOpened} />
                    </motion.div>
                </AnimatePresence>
            )}
            <div className='mobileNavIcon clickable' onClick={() => {setIsOpened(true)}}>
                <Menu/>
            </div>
            <div className='logo'>
                <Link to="/">
                    <img src='/images/logo.svg' alt='Logo' className='default-logo' />
                    <img src='/images/logo-white.svg' alt='Logo' className='dark-logo' />
                </Link>
            </div>

            <div className='leftSide'>
                <div className='darkModeSwitch'>
                    <Switch/>
                </div>
                <div className='previewButton'>
                    <a href={link} target='_blank'rel='noreferrer'> 
                        <Button title='Холбоосыг харах' icon='eye' />
                    </a>
                </div>
                <div className='previewLink'>
                    <a href={link} target='_blank'rel='noreferrer'> 
                        Харах
                    </a>
                    </div>
            </div>
        </div>
    );
};


function useOutsideAlerter(mobileNavRef, isOpened, setIsOpened) {
    useEffect(() => {
        function handleClickOutside(event) {
            if(isOpened === true){
                if (mobileNavRef.current && !mobileNavRef.current.contains(event.target)) {
                    setIsOpened(false);
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mobileNavRef, isOpened, setIsOpened]);
}


function MobileNav({ data, isOpened, setIsOpened }){
    const { home, stats, cart, settings, support } = data;

    const classNameDefault = 'menuIconSingle';
    const homeClass = classNameDefault + (home === true ? ' activeIcon' : '');
    const statsClass = classNameDefault + (stats === true ? ' activeIcon' : '');
    const cartClass = classNameDefault + (cart === true ? ' activeIcon' : '');
    const settingsClass = classNameDefault + (settings === true ? ' activeIcon' : '');
    const supportClass = classNameDefault + (support === true ? ' activeIcon' : '');

    return (
        <>
            <div className='topIcons setIcons'>
                <div className='closeMenu clickable' onClick={() => {setIsOpened(false)}}>
                    <MenuClose />
                </div>
                <Link to='/'>
                <div className={homeClass}>
                    <Home/>
                </div>
                </Link>
                <Link to='/stats'>
                <div className={statsClass}>
                    <Stats/>
                </div>
                </Link>
                {/* <Link to='/cart'>
                <div className={cartClass}>
                    <img src={cartIcon} alt='home' />
                </div>
                </Link> */}
                <Link to='/settings'>
                <div className={settingsClass}>
                    <Settings/>
                </div>
                </Link>
                {/* <Link to='/support'>
                <div className={supportClass}>
                    <img src={supportIcon} alt='home' />
                </div>
                </Link> */}
            </div>
            <div className='bottomIcons setIcons'>
                <Link to='/logout'>
                <div className='menuIconSingle'>
                    <img src='/images/icons/logout.svg' alt='home' />
                </div>
                </Link>
            </div>
        </>
    )
};

export default Header;