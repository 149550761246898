import { useEffect, useState } from 'react';
import { sendGet } from '../../utils/request';

import { Clicks, Percent } from '../../icons/StatsIcons';


function LinkStats({ links }){
    const [clicks, setClicks] = useState([]);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const res = await sendGet('/api/getClicksStats');
            if(!res) return setNoData(true);
            setClicks(res.data);
        };
        getData();
    }, []);

    return (
        <div className='linkStats block'>
            <div className='top'>
                <div className='title'>
                    Холбоосын статистик
                </div>
                {/* <div className='setDate'>
                    <Button title='Сараар харах'/>
                </div> */}
            </div>

            <div className='content'>
            { noData ? (<div style={{textAlign: 'center', fontWeight: 500}}>Хоосон байна</div>)
                : 
            (
            <div className='linksContent'>
                {links.length > 0 && links.map((link) => (
                    <div className='linksContentSingle' key={link.id}>
                        <div className='linksContentTitle'>
                            {link.title}
                        </div>
                        <div className='linksContentURL'>
                            {link.url}
                        </div>
                        <div className='linksContentStats'>
                            <div className='linkContentStatsSingle tooltipTrigger'>
                                <div className='tooltip tooltipBottom'>
                                    Энэ холбоос дээр дарсан хүний тоо
                                </div>
                                <div className='clicksIconBox linkIconBox'>
                                    <Clicks /> 
                                </div>
                                {getClicks(link.id, clicks)}
                            </div>

                            <div className='linkContentStatsSingle tooltipTrigger'>
                                <div className='tooltip tooltipBottom'>
                                    Нийт дарсан тооны эзэлж буй хувь
                                </div>
                                <div className='percentIconBox linkIconBox'>
                                    <Percent /> 
                                </div>
                                {getPercent(getClicks(link.id, clicks), clicks)}%
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            ) }
            </div>
        </div>
    )
};


function getClicks(id, clicks){
    const filter = clicks.filter(c => c.linkID == id);
    return filter.length;
};

function getPercent(clicked, clicks){
    const total = clicks.length;
    if(clicked == 0) return 0;
    return Math.floor(parseInt(clicked) / parseInt(total) * 100);
};


export default LinkStats;