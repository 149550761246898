import React from 'react';
import { motion } from "framer-motion";

function LoadingScreen(){
    return (
        <div className='loadingScreen'>
            <motion.span
                animate={{
                    scale: [1, 1.5, 1.5, 1, 1],
                    rotate: [0, 0, 180, 180, 0],
                    borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                }}
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: Infinity,
                    repeatDelay: 1
                }}
            >
                <img src='/images/logo-icon.svg' alt='Logo'/>
            </motion.span>
        </div>
    )
}

export default LoadingScreen;