import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import MainStatsSingle from './stats/MainStatsSingle';

function DashboardStats({ views, clicks, ctr, hideMore }) {
    return (
        <div className='dashboardStats block'>
            <div className='top'>
                <div className='title'>
                    Миний статистик
                </div>
                {hideMore == 'false' && (
                    <div className='secondary'>
                        <Link to='/stats'>Дэлгэрэнгүй</Link>
                    </div>
                )}
            </div>

            <div className='statsContent'>
                <MainStatsSingle icon='view' number={views} desc='харсан' color='#267AE9' tooltip='Нийт харсан хүний тоо'/>
                <MainStatsSingle icon='click' number={clicks} desc='дарсан' color='#07AF18' tooltip='Нийт холбоос дээр дарсан хүний тоо'/>
                <MainStatsSingle icon='ctr' number={ctr + '%'} desc='дундаж CTR' color='#FFA500' tooltip='Нийт даралтын хувь'/>
            </div>
            
        </div>
    )
}

export default DashboardStats;
