import { useState } from 'react';
import { Copy } from '../icons/Icons';

import ActionMessage from './ActionMessage';

function ProfileLink(props){
    const { username } = props;
    const [success, setSuccess] = useState(false);
    const link = process.env.REACT_APP_LANDING_PAGE_URL + username;
    
    const copy = () => {
        navigator.clipboard.writeText(link);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
        }, 4*1000);
    };

    return (
        <>
            {success && <ActionMessage message='Амжилттай хууллаа 😊👌' type='success' />}
            <div className='profileLink'>
                <div className='text'>
                    mybio.mn/{username}
                </div>
                <div className='copyIcon' onClick={copy}>
                    <Copy/>
                </div>
            </div>
        </>
    );
}

export default ProfileLink;