import React from 'react';

function BackgroundImage(){
    return (
        <div className='profileSelect block'>
            <div className='top'>
                <div className='title'>
                    Дэвсгэр зураг
                </div>
                <div className='secondary'>
                    Тун удахгүй
                </div>
            </div>
        </div>
    )
}


export default BackgroundImage;