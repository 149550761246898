import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setCookie, getCookie, deleteCookie, isEmailValid } from '../utils/utils';
import { AnimatePresence } from 'framer-motion';

import ActionMessage from '../components/ActionMessage';
import Button from '../components/Button';
import LoadingScreen from '../components/LoadingScreen';

function ForgotPass(){
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [forgotError, setForgotError] = useState('');
    const [forgotSuccess, setForgotSuccess] = useState(false);

    useEffect(() => {
        document.title = 'Нууц үг сэргээх';
        document.body.style.backgroundColor = "#ffffff";
        if(getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME)) return navigate("/");
        setLoading(false);
        if(code) {
            async function navReset() {
                const emailFromCookie = getCookie('resetEmail');
                if(!emailFromCookie) return navigate('/forgot');
                const checkRes = await axios.post('/auth/checkVerification', { email: emailFromCookie, code, type: 'password' });
                if(!checkRes.data) return navigate('/forgot');
                if(checkRes.data.status !== 200) return navigate('/forgot');
                setCookie('resetToken', checkRes.data.token, 1);
                deleteCookie('resetEmail');
                navigate('/resetpassword');
                return;
            }
            navReset();
        }
        // eslint-disable-next-line
    }, []);

    const reset = async () => {
        setLoadingButton(true);
        if(!email) return showError('Имэйл хоосон байна');
        if(!isEmailValid(email)) return showError('Имэйл буруу байна');
        const res = await axios.post('/auth/sendVerification', { email, type: 'password' });
        if(!res.data) return showError('Серверын холболтын алдаа гарлаа');
        if(!(res.data.status === 200 || res.data.status === 404)) return showError(res.data.error);
        setCookie('resetEmail', email, 1);
        setForgotSuccess(true);
    };

    const emailHandleChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        if (e.target.value === '') {
            e.target.style.outline = 'none';
            return setEmailError(null);
        }
        if (!isEmailValid(e.target.value)) {
            e.target.style.outline = '2px solid #B00020';
            return setEmailError('Имэйл хаяг буруу байна!');
        }
        e.target.style.outline = 'none';
        setEmailError(null);
    };

    const enterHandler = (e) => {
        if(e.key === 'Enter' && !loadingButton) reset();
    };

    const showError = (message) => {
        setLoadingButton(false);
        setForgotError(message);
        setTimeout(() => {
            setForgotError('');
        }, 8*1000);
    };

    var landingURL = '/';
    if(process.env.REACT_APP_LANDING_PAGE_URL) landingURL = process.env.REACT_APP_LANDING_PAGE_URL;

    const render = (
        <div className='loginRegister'>
            <AnimatePresence>
                {forgotError && <ActionMessage message={forgotError} type='fail' style={{top: '20px'}} />}
                {forgotSuccess && <ActionMessage message={forgotSuccess} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='title'>
                    Нууц үгээ мартсан уу?
                </div>
                <div className='desc'>
                    Зүгээр дээ бид танд тусалъя 😊
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Имэйл хаяг</span>
                        </div>
                        <input type='email' onChange={emailHandleChange} onKeyDown={enterHandler} value={email} placeholder='Имэйл хаягаа оруулна уу'/>
                        { emailError && <div className='errorMessage'>{emailError}</div> }
                    </div>
                    <div className='inputButton'>
                        {loadingButton ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Код илгээх' size='100%' onClick={reset}/>
                        )}
                        <Button title='Буцах' onClick={() => navigate(-1)} secondary={true} size='100%' styles={{marginTop: '20px'}}/>
                    </div>
                </div>
                <div className='noAccount'>
                    Нууц үгээ саначихсан уу? <Link to='/login'><span>Нэвтрэх</span></Link>
                </div>
            </div>
        </div>
    );


    const successRender = (
        <div className='loginRegister'>
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>
            <div className='container' style={{textAlign: 'center'}}>
                <div className='title'>
                    Амжилттай илгээлээ ✈️
                </div>
                <div className='desc'>
                    {email} хаяг руу илгээлээ <br/><br/>
                    СПАМ хэсгээ шалгахаа битгий мартаарай 😊
                </div>
                <Link to='/login'><Button title='Нэвтрэх'/></Link>
            </div>
        </div>
    );

    return (
        <>
            {loading ? (<LoadingScreen/>) : 
                (<>
                    { forgotSuccess ? successRender : render }
                </>)
            }
        </>
    );
}

export default ForgotPass;