import React from 'react';


function VerifyEmailMessage(){
    return (
        <div className='verifyEmailMessage'>
            <div className='colorContain'>
                Та имэйл хаягаа баталгаажуулсны дараа таны холбоос идэвхжих болно 🤗. Хэрэв танд имэйл ирээгүй бол спам хэсгээ шалгаад үзээрэй ✌️
            </div>
        </div>
    )
};

export default VerifyEmailMessage;