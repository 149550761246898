import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUsernameValid, isUsernameExist, getCookie, deleteCookie } from '../utils/utils';
import { sendPost, sendGet } from '../utils/request';


import LoadingScreen from '../components/LoadingScreen';
import ActionMessage from '../components/ActionMessage';
import Button from '../components/Button';


function Username() {
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {   
        document.title = 'Хэрэглэгчийн нэр сонгох';
        document.body.style.backgroundColor = "#ffffff";
        const checkLogged = async () => {
            const res = await sendGet('/api/validateUser');
            if(!res) return navigate('/register');
            if(!(res.status == 200 || res.status == 999)) return navigate('/register');
            if(!getCookie('noUsername')) return navigate('/');
            setLoading(false);
        }
        checkLogged();
    }, []);


    const usernameHandleChange = async (e) => {
        const usernameCurr = e.target.value;
        setUsername(usernameCurr);
        if (usernameCurr == '') {
            e.target.style.outline = 'none';
            return setUsernameError(null);
        }
        if(usernameCurr.length < 3) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('3 ба түүнээс дээш урттай байх ёстой');
        }
        if (!isUsernameValid(usernameCurr)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Зөвхөн үсэг, тоо, _ болон цэг ашиглана уу');
        }
        if (await isUsernameExist(usernameCurr)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Аль хэдийн бүртгэлтэй байна!');
        }
        e.target.style.outline = 'none';
        setUsernameError(null);
    };

    const selectUsername = async () => {
        setLoadingButton(true);
        if(!username) return showError('Хэрэглэгчийн нэр хоосон байна');
        if(!isUsernameValid(username)) return showError('Хэрэглэгчийн нэрээ зөв оруулна уу');
        if(await isUsernameExist(username)) return showError('Бүртгэлтэй хэрэглэгчийн нэр байна');
        const res = await sendPost('/api/updateUsername', { username });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status != 200) return showError(res.error);
        setSuccess('Амжилттай бүртгэгдлээ 😊');
        deleteCookie('noUsername');
        setTimeout(() => {
            setSuccess('');
            navigate("/");
        }, 1000);
    };

    const enterHandler = (e) => {
        if(e.key == 'Enter' && !loadingButton) selectUsername();
    };

    const showError = (message) => {
        setLoadingButton(false);
        setError(message);
        setTimeout(() => {
            setError('');
        }, 4*1000);
    };


    var landingURL = '/';
    if(process.env.REACT_APP_LANDING_PAGE_URL) landingURL = process.env.REACT_APP_LANDING_PAGE_URL;


    const render = (
        <div className='loginRegister'>
            {error && <ActionMessage message={error} type='fail' />}
            {success && <ActionMessage message={success} type='success' />}
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='title'>
                    Хэрэглэгчийн нэр
                </div>
                <div className='desc'>
                    Одоо сүүлийн шат 😊
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Хэрэглэгчийн нэр</span>
                        </div>
                        <input type='text' value={username} onChange={usernameHandleChange} onKeyDown={enterHandler} placeholder='mybio.mn/таны.хэрэглэгчийн.нэр'/>
                        { usernameError && <div className='errorMessage'>{usernameError}</div> }
                    </div>
                    <div className='inputButton'>
                        {loadingButton ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Дараах' icon='next' position='right' size='100%' onClick={selectUsername}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
        { loading ? <LoadingScreen/> : render }
        </>
    )
};


export default Username;