import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { AnimatePresence } from 'framer-motion';
import { getRandomInt, setCookie, getCookie, isEmailValid, isEmailExist, isNameValid, isUsernameValid, isUsernameExist, containsOneUpper, containsOneLower, containsOneNumber, deleteCookie } from '../utils/utils';

import Button from '../components/Button';
import ActionMessage from '../components/ActionMessage';
import LoadingScreen from '../components/LoadingScreen';

function Register(){
    let { step } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loading1Button, setLoading1Button] = useState(false);
    const [loading2Button, setLoading2Button] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState('');
    const [page, setPage] = useState(null);

    const query = new URLSearchParams(window.location.search);
    const queryUsername = query.get('username');

    const [enoughLength, setEnoughLength] = useState('/images/icons/password-indicator.svg');
    const [oneUpper, setOneUpper] = useState('/images/icons/password-indicator.svg');
    const [oneLower, setOneLower] = useState('/images/icons/password-indicator.svg');
    const [oneNumber, setOneNumber] = useState('/images/icons/password-indicator.svg');

    useEffect(() => {
        document.title = 'Бүртгүүлэх';
        document.body.style.backgroundColor = "#ffffff";
        if(getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME)) return navigate("/");
        var extra = '';
        if(queryUsername) extra+='?username=' + queryUsername;
        console.log('step: ', step);
        if(!step) {
            setPage(1);
            navigate("/register/1"+extra);
        }
        if(step == 1 || page == 1){
            setPage(1);
            if(getCookie('registerEmail')) setEmail(getCookie('registerEmail').toLowerCase());
        }
        if(step == 2 || page == 2) {
            setPage(2);
            if(!getCookie('registerEmail')) {
                setPage(1);
                navigate("/register/1"+extra);
            }
            console.log(queryUsername);
            if(queryUsername && isUsernameValid(queryUsername)) setUsername(queryUsername);
        }
        setLoading(false);
        // eslint-disable-next-line
    }, [page, setPage]);

    var landingURL = '/';
    if(process.env.REACT_APP_LANDING_PAGE_URL) landingURL = process.env.REACT_APP_LANDING_PAGE_URL;

    const step1Execute = async () => {
        setLoading1Button(true);
        if(!email) return showError('Имэйл хаяг хоосон байна', 1);
        if(!isEmailValid(email)) return showError('Имэйл хаяг буруу байна', 1);
        if(await isEmailExist(email)) return showError('Имэйл хаяг аль хэдийн бүртгэлтэй байна', 1);
        if(!password) return showError('Нууц үг хоосон байна', 1);
        if(password.length < 8) return showError('Нууц үг 8-аас бага урртай байна', 1);
        if(!containsOneUpper(password)) return showError('Нууц үг дор хаяж 1 том үсэг ороогүй байна', 1);
        if(!containsOneLower(password)) return showError('Нууц үг дор хаяж 1 жижиг үсэг ороогүй байна', 1);
        if(!containsOneNumber(password)) return showError('Нууц үг дор хаяж 1 тоо ороогүй байна', 1);
        const res = await axios.post('/api/addTempUser', { email: email.toLowerCase(), password });
        if(res.data.status != 200) return showError('Дараа дахин оролдоно уу', 1);
        setCookie('registerEmail', email.toLowerCase(), 1);
        setLoading1Button(false);
        setPassword('');
        setEnoughLength('/images/icons/password-indicator.svg');
        setOneUpper('/images/icons/password-indicator.svg');
        setOneLower('/images/icons/password-indicator.svg');
        setOneNumber('/images/icons/password-indicator.svg');
        var extra = '';
        if(queryUsername) extra+='?username=' + queryUsername;
        setPage(2);
        navigate("/register/2" + extra);
    };

    const step2Execute = async () => {
        setLoading2Button(true);
        var extra = '';
        if(queryUsername) extra+='?username=' + queryUsername;
        if(!name) return showError('Таны нэр хоосон байна', 2);
        if(!username) return showError('Хэрэглэгчийн нэр хоосон байна', 2);
        if(!isNameValid(name)) return showError('Өөрийн нэрээ зөв оруулна уу', 2);
        if(!isUsernameValid(username)) return showError('Хэрэглэгчийн нэрээ зөв оруулна уу', 2);
        if(await isUsernameExist(username)) return showError('Бүртгэлтэй хэрэглэгчийн нэр байна', 2);
        var cookieEmail = getCookie('registerEmail');
        if(!cookieEmail) return navigate("/register/1"+extra);
        const step1Res = await axios.get('/api/getTempUser/'+cookieEmail.toLowerCase());
        if(!step1Res.data) return showError('Серверын холболтын алдаа гарлаа', 2);
        if(step1Res.data.status != 200) {
            deleteCookie('registerEmail');
            setPage(1);
            return navigate("/register/1"+extra);
        }
        const ipData = await axios.get('https://geolocation-db.com/json/');
        var country = {};
        if(ipData) country = ipData.data;

        const newUser = {
            username,
            email: step1Res.data.data.email,
            password: step1Res.data.data.password,
            name,
            country,
            profileImage: 'avatar' + getRandomInt(1,10) + '.png'
        };

        await axios.get('/api/deleteTempUser/'+cookieEmail);
        const addNewUserRes = await axios.post('/api/addUser', newUser);
        if(!addNewUserRes.data || addNewUserRes.data.status != 200) {
            showError('Бүтгүүлэхэд алдаа гарлаа', 2);
            return setTimeout(() => {
                setPage(1);
                navigate("/register/1" + extra);
            }, 1000);
        }
        const sendCode = await axios.post('/auth/sendVerification', { email: addNewUserRes.data.data.email, type: 'email' });
        const loginRes = await axios.post('/auth/login', { email: addNewUserRes.data.data.email, password: step1Res.data.data.password });
        if(!loginRes.data || loginRes.data.status != 200) {
            showError('Бүтгүүлэхэд алдаа гарлаа', 2);
            return setTimeout(() => {
                setPage(1);
                navigate("/register/1"+extra);
            }, 1000);
        }
        deleteCookie('registerEmail');
        setCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME, loginRes.data.accessToken);
        setCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME, loginRes.data.refreshToken);
        setRegisterSuccess('Амжилттай бүртгэгдлээ 😊');
        setTimeout(() => {
            setRegisterSuccess('');
            navigate("/");
        }, 1000);
    };

    const emailHandleChange = async (e) => {
        const email = e.target.value;
        setEmail(email);
        if (email == '') {
            e.target.style.outline = 'none';
            return setEmailError(null);
        }
        if (!isEmailValid(email)) {
            e.target.style.outline = '2px solid #B00020';
            return setEmailError('Имэйл хаяг буруу байна!');
        }
        if (await isEmailExist(email)) {
            e.target.style.outline = '2px solid #B00020';
            return setEmailError('Аль хэдийн бүртгэлтэй байна!');
        }
        e.target.style.outline = 'none';
        setEmailError(null);
    };

    const usernameHandleChange = async (e) => {
        const username = e.target.value;
        setUsername(username);
        if (username == '') {
            e.target.style.outline = 'none';
            return setUsernameError(null);
        }
        if(username.length < 3) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('3 ба түүнээс дээш урттай байх ёстой');
        }
        if (!isUsernameValid(username)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Зөвхөн үсэг, тоо, _ болон цэг ашиглана уу');
        }
        if (await isUsernameExist(username)) {
            e.target.style.outline = '2px solid #B00020';
            return setUsernameError('Аль хэдийн бүртгэлтэй байна!');
        }
        e.target.style.outline = 'none';
        setUsernameError(null);
    };

    const passwordHandleChange = (e) => {
        const password = e.target.value;
        const empty = '/images/icons/password-indicator.svg';
        const done = '/images/icons/password-indicator-done.svg';
        setPassword(password);
        if(password.length >= 8) setEnoughLength(done);
        else setEnoughLength(empty);
        if(containsOneUpper(password)) setOneUpper(done);
        else setOneUpper(empty);
        if(containsOneLower(password)) setOneLower(done);
        else setOneLower(empty);
        if(containsOneNumber(password)) setOneNumber(done);
        else setOneNumber(empty);
    };

    const nameHandleChange = (e) => {
        const name = e.target.value;
        setName(name);
        if (name == '') {
            e.target.style.outline = 'none';
            return setNameError(null);
        }
        if (!isNameValid(name)) {
            e.target.style.outline = '2px solid #B00020';
            return setNameError('Нэр буруу байна!');
        }
        e.target.style.outline = 'none';
        setNameError(null);
    };

    const enterHandlerStep1 = (e) => {
        if(e.key == 'Enter' && !loading1Button) step1Execute();
    };

    const enterHandlerStep2 = (e) => {
        if(e.key == 'Enter' && !loading2Button) step2Execute();
    };


    const registerWithGoogle = async (credits) => {
        setLoading1Button(true);
        const ipData = await axios.get('https://geolocation-db.com/json/');
        if(ipData) credits.country = ipData.data;
        const res = await axios.post('/auth/google', credits);
        if(res.data.status !== 200) return showError('Алдаа гарлаа. Дахин оролдоно уу.');
        setCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME, res.data.accessToken);
        setCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME, res.data.refreshToken);
        if(res.data.newUser == true) {
            setCookie('noUsername', true);
            return navigate("/selectusername");
        }
        setRegisterSuccess('Тавтай морил 😊');
        setTimeout(() => {
            setLoading1Button(false);
            setRegisterSuccess('');
            navigate("/");
        }, 1000);
    };


    const showError = (message, step) => {
        if(step == 1) setLoading1Button(false);
        if(step == 2) setLoading2Button(false);
        setRegisterError(message);
        setTimeout(() => {
            setRegisterError('');
        }, 8*1000);
    };

    const renderStep1 = (
        <div className='loginRegister'>
            <AnimatePresence>
                {registerError && <ActionMessage message={registerError} type='fail' style={{top: '20px'}} />}
                {registerSuccess && <ActionMessage message={registerSuccess} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='step'>
                    Ахлам 1/2
                </div>
                <div className='title'>
                    Бүртгэл үүсгэх
                </div>
                <div className='desc'>
                    Бидэнд дараах мэдээлэл шаардлагатай 😊
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Имэйл хаяг</span>
                        </div>
                        <input type='email' value={email} onChange={emailHandleChange} onKeyDown={enterHandlerStep1} placeholder='Имэйл хаягаа оруулна уу'/>
                        { emailError && <div className='errorMessage'>{emailError}</div> }
                    </div>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Нууц үг</span>
                        </div>
                        <input type='password' value={password} onChange={passwordHandleChange} onKeyDown={enterHandlerStep1} />
                    </div>
                    <div className='passwordIndicator'>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneLower} alt='pass'/>
                            <span>Дор хаяж 1 жижиг үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneUpper} alt='pass'/>
                            <span>Дор хаяж 1 том үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneNumber} alt='pass'/>
                            <span>Дор хаяж 1 тоо орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={enoughLength} alt='pass'/>
                            <span>8 дээш урттай байх</span>
                        </div>
                    </div>
                    <div className='inputButton'>
                        {loading1Button ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Дараах' icon='next' position='right' size='100%' onClick={step1Execute}/>
                        )}
                    </div>
                </div>
                <div className='noAccount'>
                    Аль хэдийн бүртгэлтэй юу? <Link to='/login'><span>Нэвтрэх</span></Link>
                </div>

                <div className='loginWithSocial'>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                            onSuccess={credentialResponse => registerWithGoogle(credentialResponse)}
                            onError={() => showError('Алдаа гарлаа. Дахин оролдоно уу.', 1)}
                            type='standard'
                            theme='filled_black'
                            size='large'
                            text='signup_with'
                            shape='pill'
                            width='280px'
                            logo_alignment='left'
                            locale='mn'
                        />
                    </GoogleOAuthProvider>
                </div>
            </div>
        </div>
    );


    const renderStep2 = (
        <div className='loginRegister'>
            {registerError && <ActionMessage message={registerError} type='fail' />}
            {registerSuccess && <ActionMessage message={registerSuccess} type='success' />}
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='step'>
                    Ахлам 2/2
                </div>
                <div className='title'>
                    Бүртгэл үүсгэх
                </div>
                <div className='desc'>
                    Одоо сүүлийн шат 😊
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Таны нэр</span>
                        </div>
                        <input type='email' value={name} onChange={nameHandleChange} onKeyDown={enterHandlerStep2} placeholder='Өөрийн нэрээ оруулна уу'/>
                        { nameError && <div className='errorMessage'>{nameError}</div> }
                    </div>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Хэрэглэгчийн нэр</span>
                        </div>
                        <input type='text' value={username} onChange={usernameHandleChange} onKeyDown={enterHandlerStep2} placeholder='mybio.mn/таны.хэрэглэгчийн.нэр'/>
                        { usernameError && <div className='errorMessage'>{usernameError}</div> }
                    </div>
                    <div className='terms'>
                        Доорх товчийг дарснаар <a href={process.env.REACT_APP_LANDING_PAGE_URL + 'terms'} target='_blank'>Үйлчилгээний нөхцөл</a>-тэй танилцан, зөвшөөрсөн болно.
                    </div>
                    <div className='inputButton'>
                        {loading2Button ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Бүртгүүлэх' size='100%' onClick={step2Execute}/>
                        )}
                        <Button title='Буцах' onClick={() => navigate(-1)} secondary={true} size='100%' styles={{marginTop: '20px'}}/>
                    </div>
                </div>
            </div>
        </div>
    );

    var render;
    if(step == 1) render = renderStep1;
    else if(step == 2) render = renderStep2;

    return (
        <>
            {loading ? (<LoadingScreen/>) : render }
        </>
    )
};

export default Register;