import React from 'react';
import { Link } from 'react-router-dom';

import { Home, Stats, Settings } from '../icons/Icons';

function LeftMenu(props){
    const { home, stats, cart, settings, support } = props;

    const classNameDefault = 'menuIconSingle';
    const homeClass = classNameDefault + (home === true ? ' activeIcon' : '');
    const statsClass = classNameDefault + (stats === true ? ' activeIcon' : '');
    const cartClass = classNameDefault + (cart === true ? ' activeIcon' : '');
    const settingsClass = classNameDefault + (settings === true ? ' activeIcon' : '');
    const supportClass = classNameDefault + (support === true ? ' activeIcon' : '');

    return (
        <div className='leftMenu'>
            <div className='topIcons setIcons'>
                <Link to='/'>
                <div className={homeClass}>
                    <Home/>
                </div>
                </Link>
                <Link to='/stats'>
                <div className={statsClass}>
                    <Stats/>
                </div>
                </Link>
                {/* <Link to='/cart'>
                <div className={cartClass}>
                    <img src={cartIcon} alt='home' />
                </div>
                </Link> */}
                <Link to='/settings'>
                <div className={settingsClass}>
                    <Settings/>
                </div>
                </Link>
                {/* <Link to='/support'>
                <div className={supportClass}>
                    <img src={supportIcon} alt='home' />
                </div>
                </Link> */}
            </div>
            <div className='bottomIcons setIcons'>
                <Link to='/logout'>
                <div className='menuIconSingle'>
                    <img src='/images/icons/logout.svg' alt='home' />
                </div>
                </Link>
            </div>
        </div>
    );
}

export default LeftMenu;