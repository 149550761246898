import { useEffect, useState } from 'react';
import { containsOneUpper, containsOneLower, containsOneNumber, getCookie, deleteCookie } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';

import ActionMessage from '../components/ActionMessage';
import Button from '../components/Button';
import LoadingScreen from '../components/LoadingScreen';

function ResetPass(){
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [resetError, setResetError] = useState('');
    const [resetSuccess, setResetSuccess] = useState('');
    const [passRepeatError, setPassRepeatError] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const [enoughLength, setEnoughLength] = useState('/images/icons/password-indicator.svg');
    const [oneUpper, setOneUpper] = useState('/images/icons/password-indicator.svg');
    const [oneLower, setOneLower] = useState('/images/icons/password-indicator.svg');
    const [oneNumber, setOneNumber] = useState('/images/icons/password-indicator.svg');


    useEffect(() => {
        document.title = 'Нууц үг солих';
        document.body.style.backgroundColor = "#ffffff";
        if(getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME)) return navigate("/");
        async function checkToken(){
            const token = getCookie('resetToken');
            if(!token) {
                navigate('/forgot');
                return;
            }
            const res = await axios.post('/auth/checkResetToken', { token });
            if(!res.data) {
                navigate('/forgot');
                return;
            }
            if(res.data.status !== 200) {
                navigate('/forgot');
                return;
            }
            setEmail(res.data.email);
            setLoading(false);
        }
        checkToken();
        // eslint-disable-next-line
    }, []);


    const resetPass = async () => {
        setLoadingButton(true);
        if(!password) return showError('Нууц үг хоосон байна');
        if(!passwordRepeat) return showError('Нууц үгээ давтана уу');
        if(password.length < 8) return showError('Нууц үг 8-аас бага урттай байна');
        if(!containsOneUpper(password)) return showError('Нууц үг дор хаяж 1 том үсэг ороогүй байна');
        if(!containsOneLower(password)) return showError('Нууц үг дор хаяж 1 жижиг үсэг ороогүй байна');
        if(!containsOneNumber(password)) return showError('Нууц үг дор хаяж 1 тоо ороогүй байна');
        if(password !== passwordRepeat) return showError('Давтах нууц үг адилхан байх ёстой');
        const updateRes = await axios.post('/api/updateUser', { email, password });
        if(!updateRes.data) return showError('Серверын алдаа гарлаа. Дараа дахин оролдоно уу.');
        if(updateRes.data.status !== 200) return showError(updateRes.data.error);
        setResetSuccess('Амжилттай солигдлоо 😊');
        deleteCookie('resetToken');
        setTimeout(() => {
            navigate("/login");
        }, 1000);
    };


    const passwordHandleChange = (e) => {
        const password = e.target.value;
        const empty = '/images/icons/password-indicator.svg';
        const done = '/images/icons/password-indicator-done.svg';
        setPassword(password);
        if(password.length >= 8) setEnoughLength(done);
        else setEnoughLength(empty);
        if(containsOneUpper(password)) setOneUpper(done);
        else setOneUpper(empty);
        if(containsOneLower(password)) setOneLower(done);
        else setOneLower(empty);
        if(containsOneNumber(password)) setOneNumber(done);
        else setOneNumber(empty);
    };


    const passwordRepeatHandleChange = (e) => {
        const passRep = e.target.value;
        setPasswordRepeat(passRep);
        if (e.target.value === '') {
            e.target.style.outline = 'none';
            return setPassRepeatError(null);
        }
        if (password !== passRep) {
            e.target.style.outline = '2px solid #B00020';
            return setPassRepeatError('Адилхан байх ёстой!');
        }
        e.target.style.outline = 'none';
        setPassRepeatError(null);
    };


    const enterHandlerStep = (e) => {
        if(e.key === 'Enter' && !loadingButton) resetPass();
    };


    const showError = (message) => {
        setLoadingButton(false);
        setResetError(message);
        setTimeout(() => {
            setResetError('');
        }, 8*1000);
    };

    var landingURL = '/';
    if(process.env.REACT_APP_LANDING_PAGE_URL) landingURL = process.env.REACT_APP_LANDING_PAGE_URL;

    const render = (
        <div className='loginRegister'>
            <AnimatePresence>
                {resetError && <ActionMessage message={resetError} type='fail' style={{top: '20px'}} />}
                {resetSuccess && <ActionMessage message={resetSuccess} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='logoIcon'>
                <a href={landingURL}><img src='/images/logo-icon.svg' alt='Logo'/></a>
            </div>

            <div className='container'>
                <div className='title'>
                    Шинэ нууц үг
                </div>
                <div className='desc'>
                    Нууц үгээ бусад хүмүүст битгий хэлээрэй 🔐
                </div>
                <div className='form'>
                    <div className='inputSingle'>
                        <div className='inputName'>
                            <span>Нууц үг</span>
                        </div>
                        <input type='password' value={password} onChange={passwordHandleChange} onKeyDown={enterHandlerStep} />
                    </div>
                    <div className='passwordIndicator'>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneLower} alt='pass'/>
                            <span>Дор хаяж 1 жижиг үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneUpper} alt='pass'/>
                            <span>Дор хаяж 1 том үсэг орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={oneNumber} alt='pass'/>
                            <span>Дор хаяж 1 тоо орсон байх</span>
                        </div>
                        <div className='passwordIndicatorSingle'>
                            <img src={enoughLength} alt='pass'/>
                            <span>8 дээш урттай байх</span>
                        </div>
                    </div>
                    <div className='inputSingle' style={{marginTop: '20px'}}>
                        <div className='inputName'>
                            <span>Нууц үг давтах</span>
                        </div>
                        <input type='password' value={passwordRepeat} onChange={passwordRepeatHandleChange} onKeyDown={enterHandlerStep} placeholder='Нууц үгээ давтан оруулна уу'/>
                        { passRepeatError && <div className='errorMessage'>{passRepeatError}</div> }
                    </div>
                    <div className='inputButton'>
                        {loadingButton ? (
                            <Button title='Уншиж байна' size='100%'/>
                        ) : (
                            <Button title='Нууц үгээ солих' size='100%' onClick={resetPass}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            { loading ? (<LoadingScreen/>) : render }
        </>
    );
}

export default ResetPass;