import { useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { sendImage } from '../../utils/request';

import Button from '../Button';
import ActionMessage from '../ActionMessage';
import SaveReminder from '../SaveReminder';


function ProfileSelect({ profile, setProfile }){
    const [save, setSave] = useState(true);
    const [file, setFile] = useState();
    const [error, setError] = useState('');
    const fileRef = useRef(null);

    const filehandle = (e) => {
        const reader = new FileReader();
        const selectedFile = e.target.files[0];
        setFile(selectedFile)
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        reader.onload = (readerEvent) => {
            if (selectedFile.type.includes("image")) {
                setProfile(readerEvent.target.result);
                setSave(false);
            } 
            else showError('Зөвхөн зураг оруулна уу');
        };
    };

    const saveProfile = async () => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('type', 'profile');
        const res = await sendImage('/api/updateProfileImage', formData);
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status !== 200) return showError(res.error);
        setSave(true);
    };


    const showError = (message) => {
        setError(message);
        setSave(true);
        setTimeout(() => {
            setError('');
        }, 4*1000);
    };

    return (
        <div className='profileSelect block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Профайл зураг
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveProfile}/>) 
                    }
                </div>
            </div>

            <div className='content'>
                <div className='previewImage' style={{backgroundImage: 'url("'+profile+'")'}}></div>
                <br/>
                <Button title='Зураг солих' onClick={() => fileRef.current.click()} styles={{marginTop: '20px'}}/>
                <input type='file' accept='image/*' ref={fileRef} onChange={filehandle} style={{display: 'none'}}/>
            </div>
        </div>
    )
}


export default ProfileSelect;