import React from 'react';

import ButtonIcons from '../icons/ButtonIcons';

function Button(props){
    var { title, icon, position, disabled, secondary, purple, size, onClick, styles } = props;
    if(!styles) styles = {};
    var button, classNames, iconName, link;
    var extraClass = '';
    if(disabled) extraClass += 'buttonDisabled ';
    if(secondary) extraClass += 'buttonSecondary ';
    if(purple) extraClass += 'buttonPurple';
    if(size) styles.width = size;

    if(title && !icon && !position){
        classNames = 'button ' + extraClass;
        button = (
            <div className={classNames} style={styles} onClick={onClick}> 
                <span>{title}</span>
            </div>
        );
    }

    if(title && icon && !position){
        iconName = icon.toLowerCase();
        classNames = 'button buttonIcon buttonIconLeft ' + extraClass;
        button = (
            <div className={classNames} style={styles} onClick={onClick}>
                <ButtonIcons icon={iconName}/>
                <span>{title}</span>
            </div>
        );
    }

    if(title && icon && position){
        iconName = icon.toLowerCase();
        if(position === "right"){
            classNames = 'button buttonIcon buttonIconRight ' + extraClass;
            button = (
                <div className={classNames} style={styles} onClick={onClick}>
                    <span>{title}</span>
                    <ButtonIcons icon={iconName}/>
                </div>
            );
        }
        else {
            classNames = 'button buttonIcon buttonIconLeft ' + extraClass;
            button = (
                <div className={classNames} style={styles} onClick={onClick}>
                    <ButtonIcons icon={iconName}/>
                    <span>{title}</span>
                </div>
            );
        }
    }

    if(!title && icon && !position){
        iconName = icon.toLowerCase();
        classNames = 'button buttonIconOnly ' + extraClass;
        button = (
            <div className={classNames} style={styles} onClick={onClick}>
                <ButtonIcons icon={iconName}/>
            </div>
        );
    }

    return (
        <>
            {button}
        </>
    )
}

export default Button;