import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ChromePicker, SketchPicker } from 'react-color';
import { sendPost } from '../../utils/request';

import Button from '../Button';
import SaveReminder from '../SaveReminder';
import ActionMessage from '../ActionMessage';

function ColorPicker({ bgColor, setBgColor, buttonColor, setButtonColor, buttonTextColor, setButtonTextColor }){
    const [save, setSave] = useState(true);
    const [error, setError] = useState('');

    const handleBgColorChange = ({ hex }) => {
        setBgColor(hex);
        setSave(false);
    };

    const handleButtonColorChange = ({ hex }) => {
        setButtonColor(hex);
        setSave(false);
    };

    const handleButtonTextColorChange = ({ hex }) => {
        setButtonTextColor(hex);
        setSave(false);
    };

    const saveColors = async () => {
        const colors = {
            bgColor,
            buttonColor,
            buttonTextColor
        };
        const res = await sendPost('/api/updateColors', { colors: colors });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу')
        if(res.status !== 200) return showError(res.error);
        setSave(true);
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 8*1000);
    };

    const backgroundSuggestColors = ['#FFFFFF', '#212121', '#F6C9FF', '#E5FCC9', '#68A4EA', '#50E3C2', '#FFF9C5', '#FFCBD1', '#E2E2E2', '#C4E99F'];

    const buttonSuggestColors = ['#FFFFFF', '#212121', '#B60000', '#4A90E2', '#8B572A', '#BD10E0', '#9013FE', '#4A4A4A', '#F5A623', '#2BA58A'];
    
    const buttonTextSuggestColors = ['#FFFFFF', '#212121', '#791717', '#9013FE', '#68A4EA', '#2C2534', '#112743', '#43151D', '#2E4415', '#DBFEB3'];

    return (
        <div className='colorPicker block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '130px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Өнгө сонгох
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveColors}/>) 
                    }
                </div>
            </div>

            <div className='content'>
                <div className='colorPick'>
                    <div className='colorSection'>
                        <div className='colorTitle'>
                            Дэвсгэр өнгө
                        </div>
                        <ChromePicker
                            width={250}
                            disableAlpha={true}
                            color={bgColor}
                            presetColors={backgroundSuggestColors}
                            onChange={handleBgColorChange}
                        />
                    </div>
                    <div className='colorSection'>
                        <div className='colorTitle'>
                            Товчны өнгө
                        </div>
                        <ChromePicker
                            width={250}
                            disableAlpha={true}
                            color={buttonColor}
                            presetColors={buttonSuggestColors}
                            onChange={handleButtonColorChange}
                        />
                    </div>
                    <div className='colorSection'>
                        <div className='colorTitle'>
                            Товч дээр текст өнгө
                        </div>
                        <ChromePicker
                            width={250}
                            disableAlpha={true}
                            color={buttonTextColor}
                            presetColors={buttonTextSuggestColors}
                            onChange={handleButtonTextColorChange} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ColorPicker;