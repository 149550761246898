import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { sendPost } from '../../utils/request';
import DatePicker, { registerLocale } from "react-datepicker";
import { mn } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('mn', mn)


import ActionMessage from '../ActionMessage';
import Button from '../Button';
import SaveReminder from '../SaveReminder';


function PersonalInformation({ userData }){
    const [save, setSave] = useState(true);
    const [phone, setPhone] = useState(userData.phoneNumber);
    const [gender, setGender] = useState(userData.gender);
    const [birthdayDate, setBirthdayDate] = useState(userData.birthday);
    const [phoneError, setPhoneError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const phoneNumberChangeHandle = (e) => {
        const phoneNumberCurrent = e.target.value;
        var regExp = /[a-zA-Z]/g;
        if(regExp.test(phoneNumberCurrent)) return;
        setPhone(phoneNumberCurrent);
        setSave(false);
        if(phoneNumberCurrent.length == 0) {
            e.target.style.outline = 'none';
            return setPhoneError(null);
        }
        if(phoneNumberCurrent.length != 8) {
            e.target.style.outline = '2px solid #B00020';
            return setPhoneError('Утасны дугаар буруу байна');
        }
        e.target.style.outline = 'none';
        setPhoneError(null);
    };

    const genderChangeHandle = (e) => {
        const genderCurrent = e.target.value;
        if(!(genderCurrent == 'm' || genderCurrent == 'f' || genderCurrent == 'o')) return;
        setGender(genderCurrent);
        setSave(false);
    };

    const birthdayChangeHandle = (date) => {
        date = new Date(date.setHours(8));
        setBirthdayDate(date);
        setSave(false);
    }

    const saveInfo = async () => {
        if(phone && phone.length != 8) return showError('Утасны дугаар буруу байна');
        if(!(gender == null || gender == 'default') && !(gender == 'm' || gender == 'f' || gender == 'o')) return showError('Хүйс буруу байна');
        if(birthdayDate && !(birthdayDate instanceof Date)) return showError('Төрсөн огноо буруу байна');
        const res = await sendPost('/api/updateUser', { email: userData.email, phoneNumber: phone, gender, birthday: birthdayDate });
        if(!res) return showError('Алдаа гарлаа. Дахин оролдоно уу');
        if(res.status != 200) return showError(res.error);
        setSave(true);
        showSuccess('Амжилттай солигдлоо 😊');
    };

    const showError = (message) => {
        setError(message);
        setTimeout(() => {
            setError('');
        }, 2*1000);
    };

    const showSuccess = (message) => {
        setSuccess(message);
        setTimeout(() => {
            setSuccess('');
        }, 2*1000);
    };


    const maxYear = () => {
        var now = new Date(Date.now());
        now.setYear(now.getFullYear() - 13);
        return new Date(now);
    }


    return (
        <div className='personalInformation block'>
            <AnimatePresence>
                {save === false && <SaveReminder />}
                {error && <ActionMessage message={error} type='fail' style={{top: '20px'}} />}
                {success && <ActionMessage message={success} type='success' style={{top: '20px'}} />}
            </AnimatePresence>
            <div className='top'>
                <div className='title'>
                    Хувийн мэдээлэл
                </div>
                <div className='secondary'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveInfo}/>) 
                    }
                </div>
            </div>
            <div className='form'>
                <div className='formSingle'>
                    <div className='formTitle'>
                        Имэйл хаяг
                    </div>
                    <input type='text' value={userData.email} readOnly={true} style={{width: '100%', opacity: 0.7}} disabled={true}/>
                    <div className='inputDesc'>Солих боломжгүй</div>
                </div>

                <div className='formSingle'>
                    <div className='formTitle'>
                        Утасны дугаар
                    </div>
                    <input type='number' value={phone && phone} onChange={phoneNumberChangeHandle} style={{width: '100%'}} placeholder='Утасны дугаараа оруулна уу'/>
                    { phoneError && <div className='errorMessage'>{phoneError}</div> }
                </div>

                <div className='formSingle'>
                    <div className='formTitle'>
                        Хүйс
                    </div>
                    <select value={gender ? gender : 'default'} onChange={genderChangeHandle} style={{width: '100%'}} placeholder='Утасны дугаараа оруулна уу'>
                        <option value="default" disabled>Хүйсээ сонгоно уу</option>
                        <option value="m">Эрэгтэй</option>
                        <option value="f">Эмэгтэй</option>
                        <option value="o">Бусад</option>
                    </select>
                </div>

                <div className='formSingle'>
                    <div className='formTitle'>
                        Төрсөн огноо
                    </div>
                    <DatePicker 
                        locale="mn"
                        dateFormat="yyyy.MM.dd" 
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select" 
                        placeholderText='Төрсөн огноо оруулна уу' 
                        maxDate={new Date(Date.now()).setYear(new Date(Date.now()).getFullYear() - 13)}
                        selected={birthdayDate && new Date(birthdayDate)} 
                        onChange={birthdayChangeHandle}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }} />
                </div>

                <div className='mobileButton'>
                    { save ?
                        (<Button title='Хадгалсан' icon='saved' secondary={true}/>) :
                        (<Button title='Хадгалах' icon='save' onClick={saveInfo}/>) 
                    }
                </div>
            </div>
        </div>
    )
}

export default PersonalInformation;