import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// eslint-disable-next-line
import './styles/style.scss';

import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgotPass from './pages/ForgotPass';
import NotFound from './pages/NotFound';
import ResetPass from './pages/ResetPass';
import Username from './pages/Username';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import VerifyEmail from './pages/VerifyEmail';

if(localStorage.getItem("MBDark")){
    document.body.classList.add('dark');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route index element={<Dashboard />}/>
            <Route path="stats" element={<Stats />}/>
            <Route path="settings" element={<Settings />}/>
            <Route path="register" element={<Register />}/>
            <Route path="register/:step" element={<Register />}/>
            <Route path="selectusername" element={<Username />}/>
            <Route path="login" element={<Login />}/>
            <Route path="logout" element={<Logout />}/>
            <Route path="forgot" element={<ForgotPass />}/>
            <Route path="resetpassword" element={<ResetPass />}/>
            <Route path="verifyemail" element={<VerifyEmail />}/>
            <Route path="*" element={<NotFound />}/>
        </Routes>
  </BrowserRouter>
);
