import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getCookie, deleteCookie } from '../utils/utils';

function Logout(){
    const navigate = useNavigate();

    const execute = async () => {
        const token = getCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME);
        if(!token) navigate("/login");
        await axios.post('/auth/logout', {token});
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
        deleteCookie(process.env.REACT_APP_REFRESH_TOKEN_NAME);
        navigate("/login");
    };

    execute();

    return (
        <>
        </>
    );
};

export default Logout;