import React from 'react';
import { motion } from 'framer-motion';

function ActionMessage(props){
    var { message, type, style } = props;
    var className = 'actionMessage ';

    if(type === 'fail') type = 'actionMessageFail';
    else type = 'actionMessageSuccess';
    className += type;

    const spring = {
        type: "spring",
        damping: 10,
        stiffness: 100,
        duration: 6
    }

    return (
        <motion.div className={className} style={style}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={spring}
        >
            {message}
        </motion.div>
    )
}

export default ActionMessage;